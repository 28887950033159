import { IChart, ILink, INode } from "@bastinjafari/react-flow-chart";
import { createAsyncThunk } from "@reduxjs/toolkit";

import WorkflowApi from "api/WorkflowApi";
import FetchWorkflowsReq from "types/req/Workflow/FetchWorkflowsReq";
import { Node, Path } from "types/res/Workflow/WorkflowDetailRes";
import WorkflowRes, { WorkflowResBase } from "types/res/Workflow/WorkflowRes";
import DeleteWorkflowReq from "types/req/Workflow/DeleteWorkflowReq";

export const fetchWorkflows = createAsyncThunk(
  "fetchWorkflows",
  async ({ companyId, sectionId }: FetchWorkflowsReq) => {
    const res = await WorkflowApi.fetchList({ companyId, sectionId });
    return { workflows: res };
  }
);

export const fetchWorkflowDetail = createAsyncThunk(
  "fetchWorkflowDetail",
  async ({ workflowId }: { workflowId: number }) => {
    const res = await WorkflowApi.fetchDetail({ workflowId });
    return { workflow: res };
  }
);

const configureNodes = (chartNodes: INode[]): Node[] => {
  return chartNodes.map((node) => ({
    id: node.id,
    x: node.position.x,
    y: node.position.y,
    title: node.properties!.title,
    description: node.properties!.description,
    borderColor: node.properties?.borderColor,
    titleColor: node.properties?.titleColor,
    descriptionColor: node.properties?.descriptionColor,
    imageUrl: node.properties?.imageUrl,
    startTime: node.properties?.startTime,
    endTime: node.properties?.endTime,
  }));
};

const configurePaths = (links: ILink[]): Path[] => {
  return links.map((link) => ({
    id: link.id,
    fromNodeId: link.from.nodeId,
    toNodeId: link.to.nodeId!,
  }));
};

const configureWorkflowFromChart = (chart: IChart) => {
  const nodes = configureNodes(Object.values(chart.nodes));
  const paths = configurePaths(Object.values(chart.links));
  return { nodes, paths };
};

export const postWorkflow = createAsyncThunk(
  "postWorkflow",
  async ({
    chart,
    storeId,
    name,
    kana,
  }: { chart: IChart } & WorkflowResBase) => {
    const { nodes, paths } = configureWorkflowFromChart(chart);
    const res = await WorkflowApi.postWorkflow({
      nodes,
      paths,
      storeId,
      name,
      kana,
    });
    return { nodes, paths, storeId, name, ...res };
  }
);

export const putWorkflow = createAsyncThunk(
  "putWorkflow",
  async ({
    chart,
    storeId,
    name,
    kana,
    workflowId,
  }: { chart: IChart } & WorkflowRes) => {
    const { nodes, paths } = configureWorkflowFromChart(chart);
    const res = await WorkflowApi.putWorkflow({
      nodes,
      paths,
      storeId,
      name,
      kana,
      workflowId,
    });
    return { nodes, paths, storeId, name, res };
  }
);

export const deleteWorkflow = createAsyncThunk(
  "deleteWorkflow",
  async ({ req }: { req: DeleteWorkflowReq }) =>
    await WorkflowApi.deleteWorkflow(req)
);

export const copyWorkflow = createAsyncThunk(
  "copyWorkflow",
  async ({ req }: { req: { workflowId: number } }) =>
    await WorkflowApi.copyWorkflow(req)
);
