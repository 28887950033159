import React, { createContext, useContext, useMemo, useState } from "react";
import { mapValues, set } from "lodash";
import styled from "styled-components";
import {
  FlowChart,
  actions,
  IChart,
  REACT_FLOW_CHART,
} from "@bastinjafari/react-flow-chart";

import EditableNodeInner from "./EditableNodeInner";
import WorkflowDetailRes from "types/res/Workflow/WorkflowDetailRes";
import { configureChartFromWorkflow, Ports } from "redux/reducers/workflows";
import { Button, TextField } from "@material-ui/core";
import useAsyncDispatch from "hooks/useAsyncDispatch";
import { postWorkflow, putWorkflow } from "redux/actions/workflow";
import { useHistory, useParams } from "react-router-dom";
import BackdropSpinner from "components/BackdropSpinner";
import StorePicker from "components/StorePicker";
import CustomCanvasInner from "components/EditWorkflow/CustomCanvasInner";

const defaultInputPorts: Ports = {
  p0: { id: "p0", type: "input" },
};

const defaultOutputPorts: Ports = {
  p1: { id: "p1", type: "output" },
};

const defaultPorts = { ...defaultInputPorts, ...defaultOutputPorts };

const Components = {
  NodeInner: EditableNodeInner,
  CanvasInner: CustomCanvasInner,
};

const ChartComponent = () => {
  const { chart, setChart } = useContext(ChartContext);
  const stateActions = useMemo(
    () =>
      mapValues(actions, (func: any) => (...args: any) =>
        setChart((s) => ({ ...func(...args)(s) }))
      ),
    [actions]
  ) as typeof actions;
  return (
    <FlowChart
      chart={chart}
      config={{
        smartRouting: false,
        selected: chart.selected,
        zoom: { wheel: { disabled: true } },
      }}
      callbacks={{
        ...stateActions,
        onLinkComplete: (props) => {
          const {
            linkId,
            fromNodeId,
            fromPortId,
            toNodeId,
            toPortId,
            config = {},
          } = props;

          setChart(
            (chart: IChart): IChart => {
              if (
                !config.readonly &&
                (config.validateLink
                  ? config.validateLink({ ...props, chart })
                  : true) &&
                [fromNodeId, fromPortId].join() !== [toNodeId, toPortId].join()
              ) {
                if (
                  chart.nodes[fromNodeId].position.y >
                  chart.nodes[toNodeId].position.y
                ) {
                  chart.links[linkId].from = {
                    nodeId: toNodeId,
                    portId: toPortId,
                  };
                  chart.links[linkId].to = {
                    nodeId: fromNodeId,
                    portId: fromPortId,
                  };
                } else {
                  chart.links[linkId].to = {
                    nodeId: toNodeId,
                    portId: toPortId,
                  };
                }
              } else {
                delete chart.links[linkId];
              }
              return chart;
            }
          );
        },
      }}
      Components={Components}
    />
  );
};

const Container = styled.div`
  display: flex;
`;

const AsideContainer = styled.div`
  width: 320px;
  height: 100%;
  background-color: white;
  padding: 16px;
  position: sticky;
  top: 64px;
  left: 0;
  > * {
    margin-bottom: 16px;
  }
`;

const NodeDragCell = styled.div`
  border-radius: 5px;
  background-color: #333;
  width: 100%;
  text-align: center;
  color: white;
  cursor: move;
  padding: 8px;
`;

const SubmitButton = ({
  name,
  kana,
  storeId,
}: {
  name: string;
  kana: string;
  storeId?: number;
}) => {
  const { dispatch, isLoading } = useAsyncDispatch();
  const history = useHistory();
  const { workflowId } = useParams<{
    workflowId?: string;
  }>();
  const { chart } = useContext(ChartContext);
  const onClickSubmit = async () => {
    if (storeId === undefined) return alert("導入店舗を選択してください");
    if (workflowId) {
      // Edit page
      await dispatch(
        putWorkflow({
          chart,
          name,
          kana,
          storeId: storeId,
          workflowId: Number(workflowId),
        })
      );
      history.push(`/workflows/${workflowId}`);
    } else {
      // New (create) page
      const { payload } = await dispatch(
        postWorkflow({ chart, name, storeId, kana })
      );
      history.push(`/workflows/${payload.workflowId}`);
    }
  };
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        style={{ marginTop: 32 }}
        onClick={onClickSubmit}
      >
        送信
      </Button>
      <BackdropSpinner isLoading={isLoading} />
    </>
  );
};

const Aside = ({
  defaultName = "",
  defaultKana = "",
  defaultStoreId,
}: {
  defaultName?: string;
  defaultKana?: string;
  defaultStoreId?: number;
}) => {
  const { setChart } = useContext(ChartContext);
  const [name, setName] = useState(defaultName);
  const [kana, setKana] = useState(defaultKana);
  const [storeId, setStoreId] = useState(defaultStoreId);
  const onChangeName = (e: any) => {
    setName(e.target.value);
  };
  const onChangeKana = (e: any) => {
    setKana(e.target.value);
  };
  const onChangeStoreId = (e: any) => {
    setStoreId(e.target.value);
  };
  return (
    <AsideContainer>
      <TextField
        required
        label="ワークフロー名"
        variant="standard"
        fullWidth
        value={name}
        onChange={onChangeName}
      />
      <TextField
        required
        label="ワークフロー名(カナ)"
        variant="standard"
        fullWidth
        value={kana}
        onChange={onChangeKana}
      />
      <StorePicker storeId={storeId} onChange={onChangeStoreId} />
      <div>↓ドラッグ&ドロップをしてください</div>
      <NodeDragCell
        draggable
        onDragStart={(event) => {
          event.dataTransfer.setData(
            REACT_FLOW_CHART,
            JSON.stringify({
              ports: defaultPorts,
              properties: {},
            })
          );
        }}
      >
        カード追加
      </NodeDragCell>

      <SubmitButton name={name} storeId={storeId} kana={kana} />
      <Button
        fullWidth
        variant="contained"
        color="default"
        style={{ marginTop: 32 }}
        onClick={() =>
          setChart((chart) => ({ ...chart, scale: chart.scale + 0.1 }))
        }
      >
        拡大 +
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="default"
        style={{ marginTop: 10 }}
        onClick={() =>
          setChart((chart) => ({ ...chart, scale: chart.scale - 0.1 }))
        }
      >
        縮小 -
      </Button>
    </AsideContainer>
  );
};
type ChartContextType = {
  chart: IChart;
  setChart: React.Dispatch<
    React.SetStateAction<IChart<undefined, undefined, undefined, undefined>>
  >;
};

// Redux doesn't work with react-flow-chart😭
export const ChartContext = createContext({} as ChartContextType);

const ChartProvider: React.FC<{ defaultWorkflow?: WorkflowDetailRes }> = ({
  children,
  defaultWorkflow,
}) => {
  const [chart, setChart] = useState(
    configureChartFromWorkflow(defaultWorkflow, "edit")
  );
  const value = useMemo(() => ({ chart, setChart }), [chart]);
  return (
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

const EditWorkflowComponent = ({
  workflow,
}: {
  workflow?: WorkflowDetailRes;
}) => {
  return (
    <ChartProvider defaultWorkflow={workflow}>
      <Container>
        <Aside
          defaultName={workflow?.name}
          defaultKana={workflow?.kana}
          defaultStoreId={workflow?.storeId}
        />
        <ChartComponent />
      </Container>
    </ChartProvider>
  );
};

export default EditWorkflowComponent;
