import React, { useContext, useEffect, useState } from "react";
import { INodeInnerDefaultProps } from "@bastinjafari/react-flow-chart";
import styled from "styled-components";
import { Box, InputLabel, Select } from "@material-ui/core";
import { ChartContext } from "components/EditWorkflow";

const Container = styled.div<{ borderColor?: string }>`
  padding: 16px;
  max-width: 1200px;
  width: 600px;
  min-height: 260px;
  border: ${(props) =>
    props.borderColor ? `3px solid ${props.borderColor}` : "none"};
  > * {
    margin-bottom: 16px;
  }
`;

const Title = styled.div<{ color?: string }>`
  border-bottom: 1px solid #ccc;
  font-weight: 900;
  font-size: 24px;
  padding: 0px 0px 16px 0px;
  align-items: center;
  display: flex;
  line-height: 1;
  white-space: pre-wrap;
  color: ${(props) => props.color || "#000"};
  > * {
    margin-right: 8px;
  }
`;

const ListContainer = styled.div<{ color?: string }>`
  line-height: 1;
  white-space: pre-wrap;
  color: ${(props) => props.color || "#000"};
`;

type QuestionNodeProps = {
  id: string;
  title: string;
  description: string;
  borderColor?: string;
  titleColor?: string;
  descriptionColor?: string;
  imageUrl?: string;
};
const QuestionNode = ({
  id,
  title,
  description = "",
  borderColor,
  titleColor,
  descriptionColor,
  imageUrl,
}: QuestionNodeProps) => {
  const [nextNodeId, setNextNodeId] = useState("");
  const { chart, setChart } = useContext(ChartContext);
  const topNode = Object.values(chart.nodes).sort((a, b) =>
    a.position.y > b.position.y ? 1 : -1
  )[0];
  const toNodeIds = Object.values(chart.links)
    .filter((link) => link.from.nodeId === id)
    .map((link) => link.to.nodeId);
  const fromNodeIds = Object.values(chart.links)
    .filter((link) => link.to.nodeId === id)
    .map((link) => link.from.nodeId);
  const fromNodes = Object.values(chart.nodes).filter((node) =>
    fromNodeIds.includes(node.id)
  );
  const toNodes = Object.values(chart.nodes).filter((node) =>
    toNodeIds.includes(node.id)
  );
  const prevNodes = [...fromNodes, topNode].filter(function (v1, i1, a1) {
    return (
      a1.findIndex(function (v2) {
        return v1.id === v2.id;
      }) === i1
    );
  });
  const autoFocusSelect = (input: any) => {
    if (input?.id === chart.properties.nextNodeId) {
      setTimeout(() => input.focus(), 300);
    }
  };

  useEffect(() => {
    if (!nextNodeId || nextNodeId === "undefined") return;
    setChart((prev) => {
      return {
        ...prev,
        offset: {
          x: -chart.nodes[nextNodeId].position.x + 320,
          y: -chart.nodes[nextNodeId].position.y + 100,
        },
        properties: { nextNodeId },
      };
    });
  }, [nextNodeId]);
  return (
    <Container borderColor={borderColor}>
      <Title color={titleColor}>{title}</Title>
      {imageUrl && (
        <Box
          display="flex"
          justifyContent="center"
          style={{ position: "relative" }}
        >
          <img
            src={`https://tsplayer-image.s3-ap-northeast-1.amazonaws.com/${imageUrl}`}
            style={{ maxWidth: "100%" }}
            alt=""
          />
        </Box>
      )}
      <ListContainer color={descriptionColor}>{description}</ListContainer>
      {toNodes.length || prevNodes.length ? (
        <Box display="flex" flexDirection="column">
          <InputLabel>選択肢</InputLabel>
          <Select
            id={id}
            native
            value={nextNodeId}
            onChange={(event) => setNextNodeId(String(event.target.value))}
            fullWidth
            inputRef={autoFocusSelect}
            inputProps={{ id }}
          >
            <option value={""} />
            <optgroup label="前の選択肢">
              {prevNodes.map((node) => (
                <option key={node.id} value={node.id}>
                  {node.properties.title}
                </option>
              ))}
            </optgroup>
            <optgroup label="次の選択肢">
              {toNodes.map((node) => (
                <option key={node.id} value={node.id}>
                  {node.properties.title}
                </option>
              ))}
            </optgroup>
          </Select>
        </Box>
      ) : (
        ""
      )}
    </Container>
  );
};

const NodeInner = ({ node }: INodeInnerDefaultProps) => {
  return (
    <QuestionNode
      id={node.id}
      title={node.properties.title}
      description={node.properties.description}
      borderColor={node.properties.borderColor}
      titleColor={node.properties.titleColor}
      descriptionColor={node.properties.descriptionColor}
      imageUrl={node.properties.imageUrl}
    />
  );
};

export default NodeInner;
