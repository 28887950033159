import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete, Edit, FileCopy } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useModal from "hooks/useModal";
import styled from "styled-components";
import {
  createStyles,
  IconButton,
  TablePagination,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import AddIcon from "@material-ui/icons/Add";
import { UserRole } from "../types/enum/UserRole";
import UserRoleLayer from "./UserRoleLayer";
import { useDispatch } from "react-redux";
import { copyWorkflow } from "../redux/actions/workflow";
const StyledTableCell = styled(TableCell)`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "none")};
`;

export type Constructor<T> = new (...args: any[]) => T;
type Order = "asc" | "desc";
export type CommonTableProps<T, S, U> = {
  title: string;
  rows: { key: keyof T; label: string }[];
  formId: string;
  forms: any[];
  data: T[];
  values: ((item: T) => string | number | JSX.Element)[];
  addFunc?: (a: any) => any;
  updateFunc?: (a: any) => any;
  deleteFunc?: (a: any) => any;
  copyFunc?: (a: any) => any;
  addType?: Constructor<S>;
  updateType?: Constructor<U>;
  invisibleDeleteIcon?: boolean;
  onClickAdd?: () => any;
  onClickDelete?: (item: any) => any;
  onClickEdit?: (item: any) => any;
  onClick?: (item: any) => any;
  addRole: UserRole[];
  editRole: UserRole[];
  deleteRole: UserRole[];
};
const CommonTable = <T extends {}, S extends {}, U extends {}>({
  title,
  formId,
  forms = [],
  rows,
  data,
  values,
  addFunc,
  updateFunc,
  deleteFunc,
  copyFunc,
  addType,
  updateType,
  invisibleDeleteIcon,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  onClick,
  addRole,
  editRole,
  deleteRole,
}: CommonTableProps<T, S, U>) => {
  const dispatch = useDispatch();
  const [, setShowForm] = useModal(formId);
  const [, setDeleteShow] = useModal("deleteData");
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const createSortHandler = (property: keyof T) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const onClickAddButton = () => () => {
    if (onClickAdd) {
      onClickAdd();
    } else {
      setShowForm(true, { type: "addData" });
    }
  };

  const onClickEditButton = (type: string, item: any) => () => {
    if (onClickEdit) {
      onClickEdit(item);
    } else {
      setShowForm(true, { type: "editData", item });
    }
  };

  const onClickDeleteButton = (type: string, item: any) => () => {
    if (onClickDelete) {
      onClickDelete(item);
    } else {
      setDeleteShow(true, { item });
    }
  };
  function stableSort<V>(array: V[], comparator: (a: V, b: V) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [V, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box display="flex" p={1} alignItems="center">
          <Box p={1} flexGrow={1}>
            <TheTypography variant="h5">{title}一覧</TheTypography>
          </Box>
          <UserRoleLayer types={addRole}>
            <Box p={1}>
              <Button
                variant="contained"
                className={classes.button}
                endIcon={<AddIcon />}
                color="secondary"
                onClick={onClickAddButton()}
              >
                追加
              </Button>
            </Box>
          </UserRoleLayer>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {rows.map((row, i) => (
                  <TableCell
                    key={i}
                    sortDirection={orderBy === row.key ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === row.key}
                      direction={orderBy === row.key ? order : "asc"}
                      onClick={createSortHandler(row.key)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {addRole && formId === "workflowForm" && (
                  <UserRoleLayer types={editRole}>
                    <TableCell />
                  </UserRoleLayer>
                )}
                {updateType && (
                  <UserRoleLayer types={editRole}>
                    <TableCell />
                  </UserRoleLayer>
                )}
                {!invisibleDeleteIcon && (
                  <UserRoleLayer types={deleteRole}>
                    <TableCell />
                  </UserRoleLayer>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(data, getComparator(order, orderBy as string))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => {
                  return (
                    <TableRow key={i}>
                      {values.map((value, i) => (
                        <StyledTableCell
                          key={i}
                          component="th"
                          scope="row"
                          onClick={() => onClick && onClick(item)}
                        >
                          {value(item)}
                        </StyledTableCell>
                      ))}
                      {addRole && formId === "workflowForm" && (
                        <UserRoleLayer types={editRole}>
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={async () =>
                                copyFunc && (await dispatch(copyFunc(item)))
                              }
                            >
                              <FileCopy />
                            </IconButton>
                          </TableCell>
                        </UserRoleLayer>
                      )}
                      {updateType && (
                        <UserRoleLayer types={editRole}>
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={onClickEditButton("editData", item)}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </UserRoleLayer>
                      )}
                      {!invisibleDeleteIcon && (
                        <UserRoleLayer types={deleteRole}>
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={onClickDeleteButton("deleteData", item)}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </UserRoleLayer>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="ページ毎件数"
        />
      </Container>
      {addFunc && updateFunc && (
        <FormModal<S, U>
          title={title + "追加"}
          formId={formId}
          forms={forms}
          addFunc={addFunc}
          updateFunc={updateFunc}
          addType={addType}
          updateType={updateType}
        />
      )}
      {deleteFunc && <DeleteModal deleteFunc={deleteFunc} />}
    </>
  );
};

const TheTypography = styled(Typography)`
  margin: 24px 0;
`;

export default CommonTable;
