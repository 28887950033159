import { createAsyncThunk } from "@reduxjs/toolkit";
import StoreApi from "../../api/StoreApi";
import CreateStoreReq from "../../types/req/store/CreateStoreReq";
import UpdateStoreReq from "../../types/req/store/UpdateStoreReq";
import DeleteStoreReq from "../../types/req/store/DeleteStoreReq";

export const fetchStore = createAsyncThunk(
  "store/fetch",
  async () => await StoreApi.findAll()
);

export const createStore = createAsyncThunk(
  "store/create",
  async ({ req }: { req: CreateStoreReq }) => await StoreApi.create(req)
);

export const updateStore = createAsyncThunk(
  "store/update",
  async ({ req }: { req: UpdateStoreReq }) => await StoreApi.update(req)
);

export const deleteStore = createAsyncThunk(
  "store/delete",
  async ({ req }: { req: DeleteStoreReq }) => await StoreApi.delete(req)
);
