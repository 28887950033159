import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  copyWorkflow,
  deleteWorkflow,
  fetchWorkflowDetail,
  fetchWorkflows,
} from "redux/actions/workflow";
import WorkflowRes from "types/res/Workflow/WorkflowRes";
import { IPort, INode, ILink, IChart } from "@bastinjafari/react-flow-chart";

import WorkflowDetailRes, {
  Node,
  Path,
} from "types/res/Workflow/WorkflowDetailRes";
import { DateTime } from "luxon";
export type WorkflowsState = {
  list: WorkflowList;
  detail: WorkflowDetail;
};

type WorkflowList = WorkflowRes[];
export type WorkflowDetail = {
  [id: string]: WorkflowDetailRes & { chart: IChart };
};

export type Ports = {
  [id: string]: IPort;
};

type Nodes = {
  [id: string]: INode;
};

type Links = {
  [id: string]: ILink;
};

const configurePortId = (path: Path) => `${path.fromNodeId}-${path.toNodeId}`;

const configurePorts = (
  node: Node,
  allPaths: Path[],
  type: "edit" | "show"
) => {
  const fromPath = allPaths.filter((path) => path.fromNodeId === node.id);
  const toPath = allPaths.filter((path) => path.toNodeId === node.id);
  // It doesn't work if we extract these value. A bug in react-flow-chart??
  const inputPorts =
    type === "edit" && toPath.length === 0
      ? {
          p0: { id: "p0", type: "input" },
        }
      : ({} as Ports);
  const outputPorts =
    type === "edit" && fromPath.length === 0
      ? {
          p1: { id: "p1", type: "output" },
        }
      : ({} as Ports);

  fromPath.forEach((path) => {
    outputPorts[`output-${path.fromNodeId}`] = {
      id: `output-${path.fromNodeId}`,
      type: "output",
    };
  });

  toPath.forEach((path) => {
    inputPorts[`input-${path.toNodeId}`] = {
      id: `input-${path.toNodeId}`,
      type: "input",
    };
  });
  return { ...inputPorts, ...outputPorts };
};

const configureNodes = (
  nodes: Node[],
  paths: Path[],
  type: "edit" | "show"
) => {
  const result = {} as Nodes;
  nodes.forEach((node) => {
    const startTime =
      typeof node.startTime === "string"
        ? DateTime.fromFormat(node.startTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
            .plus({ hours: 9 })
            .toJSDate()
        : node.startTime;
    const endTime =
      typeof node.endTime === "string"
        ? DateTime.fromFormat(node.endTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
            .plus({ hours: 9 })
            .toJSDate()
        : node.endTime;
    if (
      !startTime ||
      !endTime ||
      (startTime &&
        endTime &&
        startTime < new Date() &&
        new Date() < endTime) ||
      type === "edit"
    ) {
      const ports = configurePorts(node, paths, type);

      result[node.id.toString()] = {
        id: node.id.toString(),
        type: "",
        position: { x: node.x, y: node.y },
        properties: {
          title: node.title,
          description: node.description,
          borderColor: node.borderColor,
          titleColor: node.titleColor,
          descriptionColor: node.descriptionColor,
          imageUrl: node.imageUrl,
          startTime,
          endTime,
        },
        readonly: false,
        ports,
      };
    }
  });

  return result;
};

const configureLinks = (paths: Path[]) => {
  const links = {} as Links;
  paths.forEach((path) => {
    const id = configurePortId(path);
    links[id] = {
      id,
      from: {
        nodeId: path.fromNodeId.toString(),
        portId: `output-${path.fromNodeId}`,
      },
      to: {
        nodeId: path.toNodeId.toString(),
        portId: `input-${path.toNodeId}`,
      },
    };
  });
  return links;
};

export const configureChartFromWorkflow = (
  workflow: WorkflowDetailRes | undefined,
  type: "edit" | "show"
): IChart => {
  const base = {
    offset: {
      x: 0,
      y: 0,
    },
    scale: 1,
    selected: {},
    hovered: {},
    nodes: {},
    links: {},
    properties: { nextNodeId: "" },
  };
  if (!workflow) return base;
  const allNodes = workflow.nodes;
  const allPaths = workflow.paths;
  const nodes = configureNodes(allNodes, allPaths, type);
  const links = configureLinks(allPaths);
  return { ...base, nodes, links };
};

const initialStateList = [] as WorkflowList;
const listReducer = createReducer(initialStateList, (builder) => {
  builder.addCase(fetchWorkflows.fulfilled, (_, { payload }) => {
    return payload.workflows;
  });
  builder.addCase(deleteWorkflow.fulfilled, (state, { payload }) => {
    return state.filter(
      (workflow) => workflow.workflowId !== payload.workflowId
    );
  });
  builder.addCase(copyWorkflow.fulfilled, (state, { payload }) => {
    return [...state, ...[payload]];
  });
});

const initialStateDetail = {} as WorkflowDetail;
const detailReducer = createReducer(initialStateDetail, (builder) => {
  builder.addCase(fetchWorkflowDetail.fulfilled, (state, { payload }) => {
    state[payload.workflow.workflowId] = {
      ...payload.workflow,
      chart: configureChartFromWorkflow(payload.workflow, "show"),
    };
  });
});

export default combineReducers({
  list: listReducer,
  detail: detailReducer,
});
