import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Main from "layouts/Main";
import Page404 from "pages/Page404";
import WorkflowDetail from "pages/WorkflowDetail";
import Signin from "pages/Signin";
import Workflows from "pages/Workflows";
import CreateWorkflowPage from "pages/CreateWorkflow";
import EditWorkflowPage from "pages/EditWorkflow";
import { useSelector } from "react-redux";
import Company from "../pages/Company";
import Section from "../pages/Section";
import Store from "../pages/Store";
import Faq from "../pages/Faq";
import User from "../pages/User";
import useAsyncDispatch from "hooks/useAsyncDispatch";
import { fetchStore } from "redux/actions/store";
import Loader from "components/Loader";

type RouteType = {
  path: string;
  component: React.FC<any>;
  exact?: boolean;
};

type Routes = {
  [key: string]: RouteType;
};

const mainRoutes: Routes = {
  landing: {
    path: "/",
    component: Workflows,
    exact: true,
  },
  createWorkflow: {
    path: "/workflows/new",
    component: CreateWorkflowPage,
    exact: true,
  },
  editWorkflow: {
    path: "/workflows/:workflowId/edit",
    component: EditWorkflowPage,
    exact: true,
  },
  workflowDetail: {
    path: "/workflows/:workflowId",
    component: WorkflowDetail,
    exact: true,
  },
  workflows: {
    path: "/workflows",
    component: Workflows,
    exact: true,
  },
  company: {
    path: "/company",
    component: Company,
  },
  section: {
    path: "/section",
    component: Section,
  },
  store: {
    path: "/store",
    component: Store,
  },
  faq: {
    path: "/faq",
    component: Faq,
  },
  user: {
    path: "/user",
    component: User,
  },
};

const unauthorizedRoutes: Routes = {
  signin: {
    path: "/login",
    component: Signin,
  },
};

const configureRoutes = (
  Layout: React.FC,
  routes: Routes,
  redirect?: string
) => {
  const components = Object.values(routes).map(
    ({ component: Component, path, exact = false }, index) => (
      <Route
        key={index}
        path={path}
        exact={exact}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  if (redirect) {
    components.push(<Redirect key="redirect" to={redirect} />);
  }

  return components;
};

const StoreLoader: React.FC = ({ children }) => {
  const { dispatch, isLoading, isReady } = useAsyncDispatch();

  useEffect(() => {
    dispatch(fetchStore());
  }, [dispatch]);

  return <Loader loading={!isReady || isLoading}>{children}</Loader>;
};

const AuthorizedLayout: React.FC = ({ children }) => {
  return (
    <StoreLoader>
      <Main>{children}</Main>
    </StoreLoader>
  );
};

const Auth: React.FC = ({ children }) => {
  const isLoggedin = useSelector((state) => Boolean(state.account.accessToken));

  return (
    <Switch>
      {isLoggedin
        ? children
        : configureRoutes(Main, unauthorizedRoutes, "/login")}{" "}
    </Switch>
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Auth>{configureRoutes(AuthorizedLayout, mainRoutes, "/")}</Auth>
        <Route render={() => <Page404 />} />
      </Switch>
    </Router>
  );
};

export default Routes;
