import { IsNotEmpty, MaxLength } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";
import Spec from "constants/Spec";

export default class CreateStoreReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  sectionId!: number;

  @MaxLength(Spec.maxLength.store.name, {
    message: DataValidationError.DATA_IS_EMPTY,
    context: {
      constraint1: Spec.maxLength.store.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.store.tel, {
    message: DataValidationError.DATA_IS_EMPTY,
    context: {
      constraint1: Spec.maxLength.store.tel,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  @MaxLength(Spec.maxLength.store.address, {
    message: DataValidationError.DATA_IS_EMPTY,
    context: {
      constraint1: Spec.maxLength.store.address,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  address!: string;

  constructor(arg: {
    sectionId: number;
    name: string;
    tel: string;
    address: string;
  }) {
    arg = arg || {};
    this.sectionId = arg.sectionId;
    this.name = arg.name;
    this.tel = arg.tel;
    this.address = arg.address;
  }
}
