import CompanyRes from "../../types/res/CompanyRes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  createCompany,
  deleteCompany,
  fetchCompany,
  updateCompany,
} from "../../redux/actions/company";
import { FormType } from "../../components/FormField";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import CreateCompanyReq from "../../types/req/company/CreateCompanyReq";
import UpdateCompanyReq from "../../types/req/company/UpdateCompanyReq";
import { UserRole } from "../../types/enum/UserRole";

const headers: { key: keyof CompanyRes; label: string }[] = [
  { key: "name", label: "名前" },
  { key: "tel", label: "電話番号" },
];

const Company = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company);
  useEffect(() => {
    dispatch(fetchCompany());
  }, []);
  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
  ];
  return (
    <CompanyTable
      title={"会社"}
      rows={headers}
      formId={"companyForm"}
      forms={forms}
      data={Object.values(companies)}
      values={[(c) => c.name, (c) => c.tel]}
      addFunc={(req) => createCompany({ req })}
      updateFunc={(req) => updateCompany({ req })}
      deleteFunc={(req) =>
        deleteCompany({ req: { companyId: req?.companyId } })
      }
      addType={CreateCompanyReq}
      updateType={UpdateCompanyReq}
      addRole={[UserRole.master]}
      editRole={[UserRole.master]}
      deleteRole={[UserRole.master]}
    />
  );
};

const CompanyTable = styled<
  React.FC<CommonTableProps<CompanyRes, CreateCompanyReq, UpdateCompanyReq>>
>(CommonTable)`
  margin-top: 24px;
`;
export default Company;
