import { IsNotEmpty, MaxLength } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";
import Spec from "constants/Spec";

export default class UpdateFaqReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  faqId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  storeId!: number;

  @MaxLength(Spec.maxLength.faq.question, {
    message: DataValidationError.DATA_IS_EMPTY,
    context: {
      constraint1: Spec.maxLength.faq.question,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  question!: string;

  @MaxLength(Spec.maxLength.faq.answer, {
    message: DataValidationError.DATA_IS_EMPTY,
    context: {
      constraint1: Spec.maxLength.faq.answer,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  answer!: string;

  constructor(arg: {
    faqId: number;
    storeId: number;
    question: string;
    answer: string;
    address: string;
  }) {
    arg = arg || {};
    this.faqId = arg.faqId;
    this.storeId = arg.storeId;
    this.question = arg.question;
    this.answer = arg.answer;
  }
}
