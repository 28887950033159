import RequestUtils from "../utils/RequestUtils";
import UserRes from "../types/res/UserRes";
import CreateUserReq from "../types/req/user/CreateUserReq";
import UpdateUserReq from "../types/req/user/UpdateUserReq";
import DeleteUserReq from "../types/req/user/DeleteUserReq";

export default class UserApi {
  static async findAll() {
    return await RequestUtils.getArray(UserRes, `/user`);
  }

  static async create(req: CreateUserReq) {
    return await RequestUtils.post(UserRes, `/user`, req);
  }

  static async update(req: UpdateUserReq) {
    return await RequestUtils.put(UserRes, `/user`, req);
  }

  static async delete(req: DeleteUserReq) {
    return await RequestUtils.delete(UserRes, `/user`, req);
  }
}
