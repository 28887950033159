import { Expose, Type } from "class-transformer";
import CompanyRes from "./CompanyRes";

export default class SectionRes {
  @Expose() readonly sectionId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
}
