import { createSlice } from "@reduxjs/toolkit";
import CompanyRes from "../../types/res/CompanyRes";
import {
  createCompany,
  deleteCompany,
  fetchCompany,
  updateCompany,
} from "../actions/company";

export type CompanyState = { [id: string]: CompanyRes };

const initialState = {} as CompanyState;

const configureCompany = (raw: CompanyRes[]) => {
  const result = {} as CompanyState;
  if (!raw) return result;
  raw.forEach((company) => {
    result[company.companyId] = company;
  });
  return result;
};
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.fulfilled, (_, { payload }) =>
      configureCompany(payload)
    );
    builder.addCase(createCompany.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.companyId]: payload.payload,
    }));
    builder.addCase(updateCompany.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.companyId]: payload.payload,
    }));

    builder.addCase(deleteCompany.fulfilled, (state, payload) => {
      delete state[payload.payload.companyId];
      return state;
    });
  },
});

export default companySlice.reducer;
