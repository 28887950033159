import { Expose, Type } from "class-transformer";
import StoreRes from "../StoreRes";
import UserRes from "../UserRes";

export class WorkflowResBase {
  @Expose() readonly storeId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
}

export default class WorkflowRes extends WorkflowResBase {
  @Expose() readonly workflowId!: number;
  @Type(() => StoreRes)
  @Expose()
  readonly store?: StoreRes;

  @Type(() => UserRes)
  @Expose()
  createdBy?: UserRes;

  @Type(() => UserRes)
  @Expose()
  updatedBy?: UserRes;

  @Type(() => Date)
  @Expose()
  readonly createdAt?: Date;

  @Type(() => Date)
  @Expose()
  readonly updatedAt?: Date;
}
