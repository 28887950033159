import AccountApi from "api/AccountApi";
import LoginCastReq from "types/req/LoginReq";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signin = createAsyncThunk(
  "account/signin",
  async ({ email, password }: { email: string; password: string }) => {
    const req = new LoginCastReq({ email, password });
    const result = await AccountApi.login(req);
    return result;
  }
);

export const logout = createAsyncThunk("account/logout", async () =>
  AccountApi.logout()
);
