import { Expose, Type } from "class-transformer";
import StoreRes from "./StoreRes";

export default class FaqRes {
  @Expose() readonly faqId!: number;
  @Expose() readonly storeId!: number;
  @Expose() readonly question!: string;
  @Expose() readonly answer!: string;
  @Type(() => StoreRes)
  @Expose()
  readonly store!: StoreRes;
}
