import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "components/Modal";
import { Button, CircularProgress } from "@material-ui/core";
import useModal from "hooks/useModal";
import FormField from "./FormField";
import { validate, ValidationError } from "class-validator";
import { Constructor } from "./CommonTable";

const configureErrorText = (error: string): string => {
  if (
    error.includes("should not be empty") ||
    error.includes("must be an array")
  ) {
    return "必須項目です。";
  }

  if (error.includes("must be shorter than or equal to 128 characters")) {
    return "128文字以内でご入力下さい。";
  }

  if (error.includes("must be shorter than or equal to 256 characters")) {
    return "256文字以内でご入力下さい。";
  }

  if (error.includes("must be an email")) {
    return "Eメールのフォーマットにして下さい。";
  }

  if (error.includes("must be shorter than or equal to 2048 characters")) {
    return "2048文字以内でご入力下さい。";
  }

  if (
    error.includes("must be a number conforming to the specified constraints")
  ) {
    return "数字をご入力下さい。";
  }

  if (error.includes("must be a boolean value")) {
    return "";
  }

  return error;
};

const configureErrors = (errors: ValidationError[]) => {
  const byProperty: any = {};
  errors.forEach((error) => {
    if (error.constraints) {
      const constraints = Object.values(error.constraints).map(
        configureErrorText
      );

      byProperty[error.property] = Object.values(constraints);
    }
  });

  return byProperty;
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 1.4rem;
  }
`;

type Props<T, S> = {
  formId: string;
  title: string;
  forms: any[];
  addFunc: (a: T) => any;
  updateFunc: (a: S) => any;
  addType?: Constructor<T>;
  updateType?: Constructor<S>;
};

const FormModal = <T extends {}, S extends {}>({
  formId,
  title,
  forms,
  addFunc,
  updateFunc,
  addType,
  updateType,
}: Props<T, S>) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useModal(formId);
  const defaultValues = showForm?.meta?.item;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const sections = useSelector((state) => state.section);
  const formTitle = showForm?.meta?.type === "addData" ? title : "編集";
  const [sectionList, setSectionList] = useState<any[]>([]);
  useEffect(() => {
    const next = defaultValues ? { ...defaultValues } : {};
    forms
      .filter((form) => Boolean(form.value))
      .forEach((form) => {
        if (defaultValues?.[form.key]) {
          next[form.key] = form.value(defaultValues[form.key]);
        }
      });
    setFormData(next);
  }, [JSON.stringify(defaultValues), forms.sort().join()]);

  const updateInfo = async (func: any) => {
    setLoading(true);
    await func();
    setFormData({});
    setLoading(false);
    setShowForm(false);
  };

  const onClickAddButton = async () => {
    if (!addType) return;
    const data = Object.assign(new addType(), { ...formData });
    const errors = await validate(data);
    if (errors.length > 0) {
      setErrors(configureErrors(errors));
    } else {
      await updateInfo(() => dispatch(addFunc(data)));
      setErrors({});
    }
  };

  const onClickUpdateButton = async () => {
    if (!updateType) return;
    const data = Object.assign(new updateType(), { ...formData });
    const errors = await validate(data);
    if (errors.length > 0) {
      setErrors(configureErrors(errors));
    } else {
      await updateInfo(() => dispatch(updateFunc(data)));
      setErrors({});
    }
  };

  const onClick =
    showForm?.meta?.type === "addData" ? onClickAddButton : onClickUpdateButton;
  const handleSetShowForm = (show: boolean) => {
    setShowForm(show);
    setFormData({});
  };

  useEffect(() => {
    if (formId !== "userForm" || !formData?.companyId) return;
    if (!Object.values(sections).length) return;
    setSectionList(
      Object.values(sections)
        .filter((section) => section.companyId === formData.companyId)
        .map((section) => ({ id: section.sectionId, name: section.name }))
    );
  }, [formData?.companyId]);

  return (
    <Modal show={showForm?.show} setShow={handleSetShowForm} title={formTitle}>
      <FormContainer>
        {forms.map((form) => {
          if (form.key === "password" && showForm?.meta?.type === "editData") {
            return;
          }
          return (
            <FormField
              key={form.key}
              label={form.label}
              formData={formData}
              setFormData={setFormData}
              formKey={form.key}
              formType={form.type}
              options={
                formId !== "userForm" ||
                !formData?.companyId ||
                !sectionList.length ||
                form.key !== "sectionId"
                  ? form.options
                  : sectionList
              }
              error={errors?.[form.key]}
            />
          );
        })}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClick}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </FormContainer>
    </Modal>
  );
};

export default FormModal;
