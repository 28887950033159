import { createAsyncThunk } from "@reduxjs/toolkit";
import SectionApi from "../../api/SectionApi";
import CreateSectionReq from "../../types/req/section/CreateSectionReq";
import UpdateSectionReq from "../../types/req/section/UpdateSectionReq";
import DeleteSectionReq from "../../types/req/section/DeleteSectionReq";

export const fetchSection = createAsyncThunk(
  "section/fetch",
  async () => await SectionApi.findAll()
);

export const createSection = createAsyncThunk(
  "section/create",
  async ({ req }: { req: CreateSectionReq }) => await SectionApi.create(req)
);

export const updateSection = createAsyncThunk(
  "section/update",
  async ({ req }: { req: UpdateSectionReq }) => await SectionApi.update(req)
);

export const deleteSection = createAsyncThunk(
  "section/delete",
  async ({ req }: { req: DeleteSectionReq }) => await SectionApi.delete(req)
);
