import queryString from "query-string";

import RequestUtils from "../utils/RequestUtils";
import WorkflowRes from "types/res/Workflow/WorkflowRes";
import FetchWorkflowsReq from "types/req/Workflow/FetchWorkflowsReq";
import WorkflowDetailRes from "types/res/Workflow/WorkflowDetailRes";
import PostWorkflowReq from "types/req/Workflow/PostWorkflowReq";
import PutWorkflowReq from "types/req/Workflow/PutWorkflowReq";
import PostWorkflowRes from "types/res/Workflow/PostWorkflowRes";
import DeleteWorkflownReq from "../types/req/Workflow/DeleteWorkflowReq";

export default class WorkflowApi {
  static async fetchList({
    companyId,
    sectionId,
  }: FetchWorkflowsReq): Promise<WorkflowRes[]> {
    const query = queryString.stringify({ companyId, sectionId });
    return await RequestUtils.getArray(WorkflowRes, `/workflow?${query}`);
  }

  static async fetchDetail({
    workflowId,
  }: {
    workflowId: number;
  }): Promise<WorkflowDetailRes> {
    return await RequestUtils.get(WorkflowDetailRes, `/workflow/${workflowId}`);
  }

  static async postWorkflow(props: PostWorkflowReq) {
    return await RequestUtils.post(PostWorkflowRes, `/workflow`, props);
  }

  static async putWorkflow(props: PutWorkflowReq) {
    return await RequestUtils.put(PostWorkflowRes, `/workflow`, props);
  }

  static async deleteWorkflow(props: DeleteWorkflownReq) {
    return await RequestUtils.delete(WorkflowRes, `/workflow`, props);
  }

  static async copyWorkflow(props: { workflowId: number }) {
    return await RequestUtils.post(
      WorkflowRes,
      `/workflow/copy/${props.workflowId}`,
      {}
    );
  }
}
