import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

enum Status {
  Init,
  Loading,
  Success,
  Error,
}

const useAsyncDispatch = () => {
  const dispatch = useDispatch();
  // This is like state machine. It has 'init', 'loading', 'error' and 'success'
  const [status, setStatus] = useState(Status.Init);
  const isReady = status === Status.Error || status === Status.Success;
  const isLoading = status === Status.Loading;

  const dispatchWrapper = useCallback(
    async (action) => {
      setStatus(Status.Loading);
      const res = await dispatch(action);
      if (res.error) {
        alert(res.error.message);
        setStatus(Status.Error);
        throw new Error(res.error.message);
      } else {
        setStatus(Status.Success);
      }

      return res;
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, isReady, isLoading };
};

export default useAsyncDispatch;
