const Spec = {
  maxLength: {
    company: {
      name: 128,
      tel: 128,
    },
    faq: {
      question: 2048,
      answer: 2048,
    },
    node: {
      title: 2048,
    },
    section: {
      name: 128,
      tel: 128,
    },
    store: {
      name: 128,
      tel: 128,
      address: 128,
    },
    workflow: {
      name: 128,
    },
    user: {
      name: 128,
      email: 128,
      password: 32,
    },
  },
};
export default Spec;
