import RequestUtils from "../utils/RequestUtils";
import StoreRes from "../types/res/StoreRes";
import CreateStoreReq from "../types/req/store/CreateStoreReq";
import UpdateStoreReq from "../types/req/store/UpdateStoreReq";
import DeleteStoreReq from "../types/req/store/DeleteStoreReq";

export default class StoreApi {
  static async findAll() {
    return await RequestUtils.getArray(StoreRes, `/store`);
  }

  static async create(req: CreateStoreReq) {
    return await RequestUtils.post(StoreRes, `/store`, req);
  }

  static async update(req: UpdateStoreReq) {
    return await RequestUtils.put(StoreRes, `/store`, req);
  }

  static async delete(req: DeleteStoreReq) {
    return await RequestUtils.delete(StoreRes, `/store`, req);
  }
}
