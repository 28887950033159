import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useAsyncDispatch from "hooks/useAsyncDispatch";
import Loader from "components/Loader";
import FaqListComponent from "./FaqListComponent";
import { fetchFaq } from "redux/actions/faq";
type Props = {
  storeId: number;
};
const FaqList = ({ storeId }: Props) => {
  const faqs = useSelector((state) => Object.values(state.faq));
  const { isReady, dispatch } = useAsyncDispatch();
  const [filterFaqs, setFilterFaqs] = useState();
  useEffect(() => {
    dispatch(fetchFaq());
  }, []);

  useEffect(() => {
    setFilterFaqs(faqs.filter((faq) => faq.storeId === storeId));
  }, [faqs.length]);

  return (
    <Loader loading={!isReady}>
      <FaqListComponent faqs={filterFaqs} />
    </Loader>
  );
};

export default FaqList;
