import { combineReducers } from "redux";
import modals from "./modals";
import account from "./account";
import workflows from "./workflows";
import company from "./company";
import section from "./section";
import store from "./store";
import faq from "./faq";
import user from "./user";
import "react-redux";

export type AppState = ReturnType<typeof reducer>;

declare module "react-redux" {
  interface DefaultRootState extends AppState {}
}

const reducer = combineReducers({
  modals,
  account,
  workflows,
  company,
  section,
  store,
  faq,
  user,
});

export default reducer;
