import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 16px;
  margin-top: 10px;
`;

const Question = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Answer = styled.div`
  font-size: 12px;
`;

const FaqListItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  return (
    <Container>
      <Question>Q: {question}</Question>
      <Answer>A: {answer}</Answer>
    </Container>
  );
};

export default FaqListItem;
