import React, { useEffect, useState } from "react";
import styled from "styled-components";

import FaqRes from "types/res/FaqRes";
import FaqListItem from "./FaqListItem";
import { TextField } from "@material-ui/core";

const Container = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 16px;
`;

const FaqListComponent = ({ faqs }: { faqs: FaqRes[] }) => {
  const [filterFaqs, setFilterFaqs] = useState<FaqRes[]>(faqs);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (!searchText) return setFilterFaqs(faqs);
    setFilterFaqs(
      faqs.filter(
        (faq) =>
          ~faq.question.indexOf(searchText) || ~faq.answer.indexOf(searchText)
      )
    );
  }, [searchText]);
  return (
    <Container>
      <Title>FAQ</Title>
      <TextField
        placeholder="検索文字を入力..."
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
      />

      {filterFaqs.map((faq) => (
        <FaqListItem
          question={faq.question}
          answer={faq.answer}
          key={faq.faqId}
        />
      ))}
    </Container>
  );
};

export default FaqListComponent;
