import RequestUtils from "../utils/RequestUtils";
import LoginReq from "../types/req/LoginReq";
import LoginRes from "../types/res/LoginRes";

export default class AccountApi {
  static async login(data: LoginReq): Promise<LoginRes> {
    return await RequestUtils.post(LoginRes, `/auth/login`, data);
  }

  static async logout() {
    return await RequestUtils.postVoid(`/auth/logout`, undefined);
  }
}
