import {
  actions,
  CanvasInnerDefault,
  ICanvasInnerDefaultProps,
} from "@bastinjafari/react-flow-chart";
import React, { useContext, useMemo, useState } from "react";
import { ChartContext } from "components/EditWorkflow/index";
import { v4 as uuidv4 } from "uuid";
import { Ports } from "redux/reducers/workflows";
import { mapValues } from "lodash";
import { Box, Button } from "@material-ui/core";
const defaultInputPorts: Ports = {
  p0: { id: "p0", type: "input" },
  p1: { id: "p1", type: "input" },
  p2: { id: "p2", type: "input" },
  p3: { id: "p3", type: "input" },
  p4: { id: "p4", type: "input" },
};

const defaultOutputPorts: Ports = {
  p5: { id: "p5", type: "output" },
  p6: { id: "p6", type: "output" },
  p7: { id: "p7", type: "output" },
  p8: { id: "p8", type: "output" },
  p9: { id: "p9", type: "output" },
};

const defaultPorts = { ...defaultInputPorts, ...defaultOutputPorts };

const CustomCanvasInner = ({ children, ...args }: ICanvasInnerDefaultProps) => {
  const [meta, setMeta] = useState(false);
  const { chart, setChart } = useContext(ChartContext);
  const stateActions = useMemo(
    () =>
      mapValues(actions, (func: any) => (...args: any) =>
        setChart((s) => ({ ...func(...args)(s) }))
      ),
    []
  ) as typeof actions;
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const config = { readonly: false };
      stateActions.onDeleteKey({ config });
    }
    if (e.key === "Meta" || e.key === "Control") {
      setMeta(true);
    }
    if (e.key === "v" && meta) {
      copyNode();
      setMeta(false);
    }
  };

  const copyNode = () => {
    if (!chart.selected?.id) return;
    setChart((prev) => {
      const currentNodes = prev.nodes;
      const baseNode = prev.nodes[chart.selected.id || ""];
      const currentProperties = prev.nodes[chart.selected.id || ""].properties;
      const id = uuidv4();
      return {
        ...prev,
        nodes: {
          ...currentNodes,
          [id]: {
            ...baseNode,
            id,
            position: {
              x: baseNode?.position.x + 50,
              y: baseNode?.position.y + 50,
            },
            properties: { ...currentProperties },
            ports: defaultPorts,
          },
        },
      };
    });
  };

  return (
    <CanvasInnerDefault {...args} onKeyDown={handleKeyDown}>
      {children}
    </CanvasInnerDefault>
  );
};
export default CustomCanvasInner;
