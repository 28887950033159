import React from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../types/enum/UserRole";
import EnumUtils from "../utils/EnumUtils";

type Props = {
  types: UserRole[];
};
const UserRoleLayer: React.FC<Props> = ({ children, types }) => {
  const shouldDisplay = useSelector((state) =>
    types.includes(
      EnumUtils.mapToEnum(UserRole, state.account.user?.role) || UserRole.viewer
    )
  );

  return shouldDisplay ? <>{children}</> : null;
};

export default UserRoleLayer;
