import React, { useEffect } from "react";
import EditWorkflowComponent from "components/EditWorkflow";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAsyncDispatch from "hooks/useAsyncDispatch";
import { fetchWorkflowDetail } from "redux/actions/workflow";

const EditWorkflowPage = () => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const workflow = useSelector(
    (state) => state.workflows.detail[Number(workflowId)]
  );
  const { dispatch, isReady } = useAsyncDispatch();

  useEffect(() => {
    dispatch(fetchWorkflowDetail({ workflowId: Number(workflowId) }));
  }, [dispatch, workflowId]);

  if (!isReady) return <div>Loading....</div>;
  return <EditWorkflowComponent workflow={workflow} />;
};

export default EditWorkflowPage;
