import StoreRes from "types/res/StoreRes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  createStore,
  deleteStore,
  fetchStore,
  updateStore,
} from "redux/actions/store";
import { FormType } from "components/FormField";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CreateStoreReq from "types/req/store/CreateStoreReq";
import UpdateStoreReq from "types/req/store/UpdateStoreReq";
import { fetchSection } from "redux/actions/section";
import { UserRole } from "types/enum/UserRole";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { fetchCompany } from "redux/actions/company";

const headers: { key: keyof StoreRes; label: string }[] = [
  { key: "sectionId", label: "会社名" },
  { key: "section", label: "拠点" },
  { key: "name", label: "名前" },
  { key: "tel", label: "電話番号" },
  { key: "address", label: "住所" },
];

const Store = () => {
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.section);
  const stores = useSelector((state) => state.store);
  const companies = useSelector((state) => state.company);
  const [filterStores, setFilterStores] = useState<any[]>([]);
  const [selectCompany, setSelectCompany] = useState<string[]>([]);
  useEffect(() => {
    dispatch(fetchStore());
    dispatch(fetchSection());
    dispatch(fetchCompany());
  }, []);
  useEffect(() => {
    setFilterStores(Object.values(stores));
  }, [stores]);

  useEffect(() => {
    setSelectCompany([
      ...Object.values(companies).map((company) => String(company.companyId)),
      "すべて",
    ]);
  }, [companies]);
  useEffect(() => {
    setFilterStores(
      Object.values(stores).filter((store) =>
        selectCompany.includes(String(store.section.companyId))
      )
    );
  }, [selectCompany]);
  const forms = [
    {
      label: "拠点",
      key: "sectionId",
      type: FormType.Select,
      options: Object.values(sections).map((section) => ({
        id: section.sectionId,
        name: section.name,
      })),
    },
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.Text,
    },
  ];
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <Box>
            <InputLabel id="select-multiple-shop">会社</InputLabel>
            <Select
              multiple
              value={selectCompany}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectCompany((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...Object.values(companies).map((company) =>
                        String(company.companyId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      Object.keys(companies).length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...Object.values(companies).map((company) =>
                        String(company.companyId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    Object.keys(companies).length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.values(companies)
                    .filter((company) =>
                      (selected as string[]).includes(String(company.companyId))
                    )
                    .map((company) => company.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectCompany.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.values(companies).map((company) => (
                <MenuItem
                  key={company.companyId}
                  value={String(company.companyId)}
                >
                  <Checkbox
                    checked={
                      selectCompany.indexOf(String(company.companyId)) !== -1
                    }
                  />
                  <ListItemText primary={company.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <StoreTable
        title={"導入店舗"}
        rows={headers}
        formId={"storeForm"}
        forms={forms}
        data={filterStores}
        values={[
          (s) => s.section.company.name,
          (s) => s.section.name,
          (s) => s.name,
          (s) => s.tel,
          (s) => s.address,
        ]}
        addFunc={(req) => createStore({ req })}
        updateFunc={(req) => updateStore({ req })}
        deleteFunc={(req) => deleteStore({ req: { storeId: req?.storeId } })}
        addType={CreateStoreReq}
        updateType={UpdateStoreReq}
        addRole={[UserRole.master, UserRole.manager]}
        editRole={[UserRole.master, UserRole.manager]}
        deleteRole={[UserRole.master, UserRole.manager]}
      />
    </>
  );
};

const StoreTable = styled<
  React.FC<CommonTableProps<StoreRes, CreateStoreReq, UpdateStoreReq>>
>(CommonTable)`
  margin-top: 24px;
`;
export default Store;
