import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { mapValues } from "lodash";
import { FlowChart, actions } from "@bastinjafari/react-flow-chart";

import NodeInner from "components/NodeInner";
import WorkflowDetailRes from "types/res/Workflow/WorkflowDetailRes";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAsyncDispatch from "hooks/useAsyncDispatch";
import { fetchWorkflowDetail } from "redux/actions/workflow";
import { configureChartFromWorkflow } from "redux/reducers/workflows";
import { Button, createStyles, Theme } from "@material-ui/core";
import Loader from "components/Loader";
import FaqList from "components/FaqList";
import UserRoleLayer from "components/UserRoleLayer";
import { UserRole } from "types/enum/UserRole";
import { makeStyles } from "@material-ui/core/styles";
import { ChartContext } from "components/EditWorkflow";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
`;

const AsideContainer = styled.div`
  width: 320px;
  height: 100%;
  background-color: white;
  padding: 16px;
`;
const FaqListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: white;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

const AsideKey = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const AsideValue = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;
const AsideItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <>
      <AsideKey>{title}</AsideKey>
      <AsideValue>{value}</AsideValue>
    </>
  );
};

const Components = { NodeInner };

const Aside = ({
  name,
  kana,
  storeId,
  workflowId,
}: {
  name: string;
  kana: string;
  storeId: number;
  workflowId: number;
}) => {
  const history = useHistory();
  const store = useSelector((state) => state.store[storeId]);
  const onClickEdit = () => {
    history.push(`/workflows/${workflowId}/edit`);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  return (
    <AsideContainer>
      <Button
        href="/"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        一覧へ戻る
      </Button>
      <AsideItem title="名前" value={name} />
      <AsideItem title="カナ" value={kana} />
      <AsideItem title="店舗名" value={store.name} />
      <UserRoleLayer types={[UserRole.master, UserRole.manager]}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onClickEdit}
        >
          編集する →
        </Button>
      </UserRoleLayer>
    </AsideContainer>
  );
};

const ChartProvider: React.FC<{ defaultWorkflow?: WorkflowDetailRes }> = ({
  children,
  defaultWorkflow,
}) => {
  const [chart, setChart] = useState(
    configureChartFromWorkflow(defaultWorkflow, "show")
  );
  const value = useMemo(() => ({ chart, setChart }), [chart]);
  return (
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

const WorkflowDetailComponent = ({
  workflow,
}: {
  workflow: WorkflowDetailRes;
}) => {
  return (
    <Container>
      <Aside
        name={workflow.name}
        kana={workflow.kana}
        storeId={workflow.storeId}
        workflowId={workflow.workflowId}
      />
      <ChartProvider defaultWorkflow={workflow}>
        <ChartComponent />
      </ChartProvider>
      <FaqListContainer>
        <FaqList storeId={workflow.storeId} />
      </FaqListContainer>
    </Container>
  );
};

const ChartComponent = () => {
  const { chart, setChart } = useContext(ChartContext);
  // Functional component has a rendering problem in the library, and we need this hack.
  const stateActions = useMemo(
    () =>
      mapValues(actions, (func: any) => (...args: any) =>
        setChart((s) => ({ ...func(...args)(s) }))
      ),
    [actions]
  ) as typeof actions;
  return (
    <ChartContainer>
      <FlowChart
        chart={chart}
        config={{
          smartRouting: false,
          readonly: true,
          zoom: { wheel: { disabled: true } },
        }}
        callbacks={stateActions}
        Components={Components}
      />
    </ChartContainer>
  );
};

const WorkflowDetail = () => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const workflow = useSelector(
    (state) => state.workflows.detail[Number(workflowId)]
  );
  const { dispatch, isReady } = useAsyncDispatch();

  useEffect(() => {
    dispatch(fetchWorkflowDetail({ workflowId: Number(workflowId) }));
  }, [dispatch, workflowId]);

  return (
    <Loader loading={!isReady}>
      <WorkflowDetailComponent workflow={workflow} />
    </Loader>
  );
};

export default WorkflowDetail;
