import { Expose, Type } from "class-transformer";
import WorkflowRes from "./WorkflowRes";

export class Node {
  id!: string;
  x!: number;
  y!: number;
  title!: string;
  description!: string;
  borderColor?: string;
  titleColor?: string;
  descriptionColor?: string;
  imageUrl?: string;
  @Type(() => Date)
  @Expose()
  startTime?: Date;
  @Type(() => Date)
  @Expose()
  endTime?: Date;
}

export type Path = {
  id: string;
  fromNodeId: string;
  toNodeId: string;
};

export default class WorkflowDetailRes extends WorkflowRes {
  @Expose() readonly nodes!: Node[];
  @Expose() readonly paths!: Path[];
}
