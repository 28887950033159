import { DataValidationError } from "constants/DataValidationError";
import { IsEmail, IsNotEmpty, IsOptional, MaxLength } from "class-validator";
import Spec from "constants/Spec";
import { UserRole } from "types/enum/UserRole";

export default class UpdateUserReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  userId!: number;

  @IsOptional()
  companyId?: number;

  @IsOptional()
  sectionId?: number;

  @MaxLength(Spec.maxLength.user.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.user.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @MaxLength(Spec.maxLength.user.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.user.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  role!: UserRole;

  constructor(arg: {
    userId: number;
    companyId?: number;
    sectionId?: number;
    name: string;
    email: string;
    role: UserRole;
  }) {
    arg = arg || {};
    this.userId = arg.userId;
    this.companyId = arg.companyId;
    this.sectionId = arg.sectionId;
    this.name = arg.name;
    this.email = arg.email;
    this.role = arg.role;
  }
}
