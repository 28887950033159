import React from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { DateTime } from "luxon";

export enum FormType {
  Text,
  TextArea,
  Number,
  Date,
  DateTime,
  Boolean,
  Select,
  MultiOptions,
  None,
}

type FormProps = {
  formKey: string;
  formType: FormType;
  label: string;
  options?: any[];
  formData: any;
  setFormData: (a: any) => any;
  error?: string[];
};

const FormField = ({
  formKey,
  formType,
  label,
  options,
  formData,
  setFormData,
  error,
}: FormProps) => {
  const onChangeForm = (
    key: string,
    handler: (value: any) => any = (value: any) => value
  ) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    event.persist();
    setFormData((prev: any) => ({
      ...prev,
      [key]: handler(event.target.value),
    }));
  };

  const onChangeMultiOptions = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!options) return;
    event.persist();
    if (event.target.checked) {
      setFormData((prev: any) => {
        const current = prev[key] || [];
        return {
          ...prev,
          [key]: [...current, Number(event.target.id)],
        };
      });
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [key]: prev[key].filter((id: number) => id !== Number(event.target.id)),
      }));
    }
  };

  switch (formType) {
    case FormType.Text:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          variant="outlined"
          defaultValue={formData[formKey]}
          fullWidth
          onChange={onChangeForm(formKey)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Number:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="number"
          variant="outlined"
          defaultValue={formData[formKey]}
          fullWidth
          onChange={onChangeForm(formKey, (value) => Number(value))}
          helperText={error && error.join("")}
        />
      );
    case FormType.Date:
      return (
        <TextField
          error={Boolean(error)}
          label={label}
          type="date"
          variant="outlined"
          defaultValue={DateTimeUtils.toFormatAsLocalTimezone(
            formData[formKey],
            FORMAT_TYPE.YEAR_DAY
          )}
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          helperText={error && error.join("")}
        />
      );
    case FormType.DateTime:
      return (
        <TextField
          label={label}
          type="datetime-local"
          variant="outlined"
          defaultValue={
            formData[formKey]
              ? DateTime.fromJSDate(formData[formKey]).toISO({
                  includeOffset: false,
                })
              : ""
          }
          fullWidth
          onChange={onChangeForm(formKey, (value) => new Date(value))}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.TextArea:
      return (
        <TextField
          label={label}
          type="textarea"
          variant="outlined"
          defaultValue={formData[formKey]}
          fullWidth
          multiline
          onChange={onChangeForm(formKey)}
          error={Boolean(error)}
          helperText={error && error.join("")}
        />
      );
    case FormType.Boolean:
      return (
        <>
          <FormLabel error={Boolean(error)} component="legend">
            {label}
          </FormLabel>
          <RadioGroup
            row
            aria-label="editCallCenterContract"
            name="editCallCenterContract"
            onChange={onChangeForm(formKey, (value) => value === "1")}
            value={formData[formKey]}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={options?.[1]?.name}
              checked={formData[formKey] === true}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={options?.[0]?.name}
              checked={formData[formKey] === false}
            />
          </RadioGroup>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.Select:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          <Select
            error={Boolean(error)}
            onChange={onChangeForm(formKey)}
            inputProps={{
              name: formKey,
              id: formKey,
            }}
            fullWidth
            value={formData[formKey]}
          >
            {options?.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.MultiOptions:
      return (
        <>
          <InputLabel error={Boolean(error)} htmlFor={formKey}>
            {label}
          </InputLabel>
          {options?.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={option.name}
                  onChange={onChangeMultiOptions(formKey)}
                  checked={formData[formKey]?.includes(option.id) ?? false}
                  id={`${option.id}`}
                />
              }
              key={option.id}
              label={option.name}
            />
          ))}
          {error && <FormHelperText error>{error.join("")}</FormHelperText>}
        </>
      );
    case FormType.None:
      return null;
    default:
      const _: never = formType;
      throw new Error(_);
  }
};

export default FormField;
