import RequestUtils from "../utils/RequestUtils";
import FaqRes from "../types/res/FaqRes";
import CreateFaqReq from "../types/req/faq/CreateFaqReq";
import UpdateFaqReq from "../types/req/faq/UpdateFaqReq";
import DeleteFaqReq from "../types/req/faq/DeleteFaqReq";

export default class FaqApi {
  static async findAll() {
    return await RequestUtils.getArray(FaqRes, `/faq`);
  }

  static async create(req: CreateFaqReq) {
    return await RequestUtils.post(FaqRes, `/faq`, req);
  }

  static async update(req: UpdateFaqReq) {
    return await RequestUtils.put(FaqRes, `/faq`, req);
  }

  static async delete(req: DeleteFaqReq) {
    return await RequestUtils.delete(FaqRes, `/faq`, req);
  }
}
