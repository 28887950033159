import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, InputLabel, MenuItem, Box } from "@material-ui/core";
import EnumUtils from "../utils/EnumUtils";
import { UserRole } from "types/enum/UserRole";
import { fetchCompany } from "redux/actions/company";
import { fetchSection } from "redux/actions/section";

const StorePicker = ({
  storeId,
  onChange,
}: {
  storeId?: number;
  onChange: any;
}) => {
  const dispatch = useDispatch();
  const stores = useSelector((state) => Object.values(state.store));
  const companies = useSelector((state) => Object.values(state.company));
  const sections = useSelector((state) => Object.values(state.section));
  const loginUserRole = useSelector((state) => state.account.user.role);
  const loginCompany = useSelector((state) => state.account.user.company);
  const [filterStores, setFilterStores] = useState<any[]>([]);
  const [filterSections, setFilterSections] = useState<any[]>([]);

  const [companyId, setCompanyId] = useState();
  const [sectionId, setSectionId] = useState();

  useEffect(() => {
    const store = stores.find((store) => store.storeId === storeId);
    setCompanyId(store?.section.companyId || null);
    setSectionId(store?.sectionId || "");
  }, [stores.length, storeId]);

  useEffect(() => {
    setFilterStores(stores);
  }, [stores.length]);

  useEffect(() => {
    setFilterSections(sections);
  }, [sections.length]);

  useEffect(() => {
    setFilterStores(
      stores.filter((store) => store.section.companyId === companyId)
    );
    setFilterSections(
      sections.filter((section) => section.companyId === companyId)
    );
  }, [companyId, stores.length, sections.length]);

  useEffect(() => {
    setFilterStores(stores.filter((store) => store.sectionId === sectionId));
  }, [sectionId]);

  useEffect(() => {
    if (EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master) {
      dispatch(fetchCompany());
    }
    if (EnumUtils.mapToEnum(UserRole, loginUserRole) !== UserRole.viewer) {
      dispatch(fetchSection());
    }
  }, [loginUserRole]);

  return (
    <Box display="flex" flexDirection="column">
      <InputLabel>会社</InputLabel>
      <Select
        value={companyId || ""}
        onChange={(e) => {
          setCompanyId(e.target.value);
        }}
        fullWidth
      >
        {EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master ? (
          companies.map((company) => (
            <MenuItem key={company.companyId} value={company.companyId}>
              {company.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={loginCompany?.companyId}
            value={loginCompany?.companyId}
          >
            {loginCompany?.name}
          </MenuItem>
        )}
      </Select>
      <InputLabel>拠点</InputLabel>
      <Select
        value={sectionId || ""}
        onChange={(e) => setSectionId(e.target.value)}
        fullWidth
      >
        {filterSections.map((section) => (
          <MenuItem key={section.sectionId} value={section.sectionId}>
            {section.name}
          </MenuItem>
        ))}
      </Select>
      <InputLabel>店舗</InputLabel>
      <Select value={storeId || ""} onChange={onChange} fullWidth>
        {filterStores.map((store) => (
          <MenuItem key={store.storeId} value={store.storeId}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default StorePicker;
