import React, { useEffect, useState } from "react";
import useAsyncDispatch from "hooks/useAsyncDispatch";
import {
  copyWorkflow,
  deleteWorkflow,
  fetchWorkflows,
} from "redux/actions/workflow";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import CommonTable from "components/CommonTable";
import { FormType } from "components/FormField";
import { useHistory } from "react-router-dom";
import WorkflowRes from "types/res/Workflow/WorkflowRes";
import { UserRole } from "types/enum/UserRole";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { fetchCompany } from "redux/actions/company";
import { fetchSection } from "redux/actions/section";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import EnumUtils from "utils/EnumUtils";
import { fetchStore } from "redux/actions/store";

const headers: { key: keyof WorkflowRes; label: string }[] = [
  { key: "name", label: "ワークフロー名" },
  { key: "kana", label: "ワークフロー名（カナ）" },
  { key: "name", label: "会社名" },
  { key: "name", label: "拠点名" },
  { key: "storeId", label: "導入店舗" },
  { key: "createdBy", label: "作成者" },
  { key: "updatedBy", label: "更新者" },
  { key: "createdAt", label: "作成時間" },
  { key: "updatedAt", label: "更新時間" },
];

const forms = [
  {
    label: "ワークフロー名",
    key: "name",
    type: FormType.Text,
  },
];
const WorkflowsComponent = ({
  workflows,
  onClickAdd,
  onClick,
}: {
  workflows: WorkflowRes[];
  onClickAdd: any;
  onClick: any;
}) => {
  return (
    <div>
      <CommonTable
        title={"ワークフロー"}
        formId="workflowForm"
        forms={forms}
        rows={headers}
        data={workflows}
        deleteFunc={(item) =>
          deleteWorkflow({ req: { workflowId: item?.workflowId } })
        }
        copyFunc={(item) =>
          copyWorkflow({ req: { workflowId: item?.workflowId } })
        }
        onClickAdd={onClickAdd}
        onClick={onClick}
        values={[
          (s) => s.name,
          (s) => s.kana,
          (s) => s?.store?.section?.company?.name || "未設定",
          (s) => s?.store?.section?.name || "未設定",
          (s) => s?.store?.name || "未設定",
          (s) => s?.createdBy?.name || "未設定",
          (s) => s?.updatedBy?.name || "未設定",
          (s) =>
            s?.createdAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.createdAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : "未設定",
          (s) =>
            s?.updatedAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.updatedAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : "未設定",
        ]}
        addRole={[UserRole.master, UserRole.manager]}
        editRole={[UserRole.master, UserRole.manager]}
        deleteRole={[UserRole.master, UserRole.manager]}
      />
    </div>
  );
};

const Workflows = () => {
  const { dispatch, isReady } = useAsyncDispatch();
  const { companyId, sectionId } = useSelector((state) => state.account.user);
  const workflows = useSelector((state) => state.workflows.list);
  const loginUserRole = useSelector((state) => state.account.user.role);
  const history = useHistory();
  const companies = useSelector((state) => state.company);
  const sections = useSelector((state) => state.section);
  const stores = useSelector((state) => state.store);
  const [filterWorkflows, setFilterWorkflows] = useState<any[]>([]);
  const [selectCompany, setSelectCompany] = useState<string[]>([]);
  const [selectSection, setSelectSection] = useState<string[]>([]);
  const [selectStore, setSelectStore] = useState<string[]>([]);

  const onClick = (item: any) => {
    history.push(`/workflows/${item.workflowId}`);
  };

  const onClickAdd = () => {
    history.push("/workflows/new");
  };

  useEffect(() => {
    if (EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master) {
      dispatch(fetchCompany());
    }
    if (EnumUtils.mapToEnum(UserRole, loginUserRole) !== UserRole.viewer) {
      dispatch(fetchSection());
      dispatch(fetchStore());
    }
  }, [loginUserRole]);

  useEffect(() => {
    setFilterWorkflows(workflows);
  }, [workflows]);

  useEffect(() => {
    dispatch(fetchWorkflows({ companyId, sectionId }));
  }, [dispatch, companyId, sectionId]);

  useEffect(() => {
    setSelectCompany([
      ...Object.values(companies).map((company) => String(company.companyId)),
      "すべて",
    ]);
  }, [companies]);

  useEffect(() => {
    setSelectSection([
      ...Object.values(sections).map((section) => String(section.sectionId)),
      "すべて",
    ]);
  }, [sections]);

  useEffect(() => {
    setSelectStore([
      ...Object.values(stores).map((store) => String(store.storeId)),
      "すべて",
    ]);
  }, [stores]);

  useEffect(() => {
    setFilterWorkflows(
      workflows.filter((workflow) =>
        selectCompany.includes(String(workflow.store?.section.companyId))
      )
    );
  }, [selectCompany]);

  useEffect(() => {
    setFilterWorkflows(
      workflows.filter((workflow) =>
        selectSection.includes(String(workflow.store?.section.sectionId))
      )
    );
  }, [selectSection]);

  useEffect(() => {
    const filter = workflows.filter((workflow) => {
      const companyCondition = selectCompany.includes(
        String(workflow.store?.section.companyId)
      );
      const sectionCondition = selectSection.includes(
        String(workflow.store?.section.sectionId)
      );
      const storeCondition = selectStore.includes(String(workflow.storeId));
      return companyCondition && sectionCondition && storeCondition;
    });

    setFilterWorkflows(filter);
  }, [workflows, selectSection, selectStore, selectCompany]);

  return (
    <Loader loading={!isReady}>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          {EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master && (
            <Box>
              <InputLabel id="select-multiple-company">会社</InputLabel>
              <Select
                multiple
                value={selectCompany}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setSelectCompany((prev: string[]) => {
                    if (
                      prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [];
                    } else if (
                      !prev.includes("すべて") &&
                      (event.target.value as string[]).indexOf("すべて") !== -1
                    ) {
                      return [
                        ...Object.values(companies).map((company) =>
                          String(company.companyId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length ===
                        Object.keys(companies).length &&
                      (event.target.value as string[]).indexOf("すべて") === -1
                    ) {
                      return [
                        ...Object.values(companies).map((company) =>
                          String(company.companyId)
                        ),
                        "すべて",
                      ];
                    } else if (
                      (event.target.value as string[]).length <=
                      Object.keys(companies).length
                    ) {
                      return (event.target.value as string[]).filter(
                        (name) => name !== "すべて"
                      );
                    } else {
                      return event.target.value as string[];
                    }
                  });
                }}
                input={<Input id="select-multiple-company" />}
                style={{ width: "200px", marginRight: "10px" }}
                renderValue={(selected) => {
                  if ((selected as string[]).includes("すべて")) {
                    return "すべて";
                  } else {
                    return Object.values(companies)
                      .filter((company) =>
                        (selected as string[]).includes(
                          String(company.companyId)
                        )
                      )
                      .map((company) => company.name)
                      .join(", ");
                  }
                }}
              >
                <MenuItem key={"すべて"} value={"すべて"}>
                  <Checkbox
                    name="all"
                    checked={selectCompany.indexOf("すべて") > -1}
                  />
                  <ListItemText primary={"すべて"} />
                </MenuItem>
                {Object.values(companies).map((company) => (
                  <MenuItem
                    key={company.companyId}
                    value={String(company.companyId)}
                  >
                    <Checkbox
                      checked={
                        selectCompany.indexOf(String(company.companyId)) !== -1
                      }
                    />
                    <ListItemText primary={company.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {EnumUtils.mapToEnum(UserRole, loginUserRole) !== UserRole.viewer && (
            <>
              <Box>
                <InputLabel id="select-multiple-section">拠点</InputLabel>
                <Select
                  multiple
                  value={selectSection}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setSelectSection((prev: string[]) => {
                      if (
                        prev.includes("すべて") &&
                        (event.target.value as string[]).indexOf("すべて") ===
                          -1
                      ) {
                        return [];
                      } else if (
                        !prev.includes("すべて") &&
                        (event.target.value as string[]).indexOf("すべて") !==
                          -1
                      ) {
                        return [
                          ...Object.values(sections).map((section) =>
                            String(section.sectionId)
                          ),
                          "すべて",
                        ];
                      } else if (
                        (event.target.value as string[]).length ===
                          Object.keys(sections).length &&
                        (event.target.value as string[]).indexOf("すべて") ===
                          -1
                      ) {
                        return [
                          ...Object.values(sections).map((section) =>
                            String(section.sectionId)
                          ),
                          "すべて",
                        ];
                      } else if (
                        (event.target.value as string[]).length <=
                        Object.keys(sections).length
                      ) {
                        return (event.target.value as string[]).filter(
                          (name) => name !== "すべて"
                        );
                      } else {
                        return event.target.value as string[];
                      }
                    });
                  }}
                  input={<Input id="select-multiple-section" />}
                  style={{ width: "200px", marginRight: "10px" }}
                  renderValue={(selected) => {
                    if ((selected as string[]).includes("すべて")) {
                      return "すべて";
                    } else {
                      return Object.values(sections)
                        .filter((section) =>
                          (selected as string[]).includes(
                            String(section.sectionId)
                          )
                        )
                        .map((section) => section.name)
                        .join(", ");
                    }
                  }}
                >
                  <MenuItem key={"すべて"} value={"すべて"}>
                    <Checkbox
                      name="all"
                      checked={selectSection.indexOf("すべて") > -1}
                    />
                    <ListItemText primary={"すべて"} />
                  </MenuItem>
                  {Object.values(sections).map((section) => (
                    <MenuItem
                      key={section.sectionId}
                      value={String(section.sectionId)}
                    >
                      <Checkbox
                        checked={
                          selectSection.indexOf(String(section.sectionId)) !==
                          -1
                        }
                      />
                      <ListItemText primary={section.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <InputLabel id="select-multiple-shop">導入店舗</InputLabel>
                <Select
                  multiple
                  value={selectStore}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setSelectStore((prev: string[]) => {
                      if (
                        prev.includes("すべて") &&
                        (event.target.value as string[]).indexOf("すべて") ===
                          -1
                      ) {
                        return [];
                      } else if (
                        !prev.includes("すべて") &&
                        (event.target.value as string[]).indexOf("すべて") !==
                          -1
                      ) {
                        return [
                          ...Object.values(stores).map((store) =>
                            String(store.storeId)
                          ),
                          "すべて",
                        ];
                      } else if (
                        (event.target.value as string[]).length ===
                          Object.keys(stores).length &&
                        (event.target.value as string[]).indexOf("すべて") ===
                          -1
                      ) {
                        return [
                          ...Object.values(stores).map((store) =>
                            String(store.storeId)
                          ),
                          "すべて",
                        ];
                      } else if (
                        (event.target.value as string[]).length <=
                        Object.keys(stores).length
                      ) {
                        return (event.target.value as string[]).filter(
                          (name) => name !== "すべて"
                        );
                      } else {
                        return event.target.value as string[];
                      }
                    });
                  }}
                  input={<Input id="select-multiple-store" />}
                  style={{ width: "200px", marginRight: "10px" }}
                  renderValue={(selected) => {
                    if ((selected as string[]).includes("すべて")) {
                      return "すべて";
                    } else {
                      return Object.values(stores)
                        .filter((store) =>
                          (selected as string[]).includes(String(store.storeId))
                        )
                        .map((store) => store.name)
                        .join(", ");
                    }
                  }}
                >
                  <MenuItem key={"すべて"} value={"すべて"}>
                    <Checkbox
                      name="all"
                      checked={selectStore.indexOf("すべて") > -1}
                    />
                    <ListItemText primary={"すべて"} />
                  </MenuItem>
                  {Object.values(stores).map((store) => (
                    <MenuItem key={store.storeId} value={String(store.storeId)}>
                      <Checkbox
                        checked={
                          selectStore.indexOf(String(store.storeId)) !== -1
                        }
                      />
                      <ListItemText primary={store.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <WorkflowsComponent
        workflows={filterWorkflows}
        onClick={onClick}
        onClickAdd={onClickAdd}
      />
    </Loader>
  );
};

export default Workflows;
