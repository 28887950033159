import { IsNotEmpty, MaxLength } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";
import Spec from "constants/Spec";

export default class UpdateSectionReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  sectionId!: number;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  companyId!: number;

  @MaxLength(Spec.maxLength.section.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.section.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.section.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.section.tel,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  constructor(arg: {
    sectionId: number;
    companyId: number;
    name: string;
    tel: string;
  }) {
    arg = arg || {};
    this.sectionId = arg.sectionId;
    this.companyId = arg.companyId;
    this.name = arg.name;
    this.tel = arg.tel;
  }
}
