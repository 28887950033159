import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/Modal";
import { Button, CircularProgress, Container } from "@material-ui/core";
import useModal from "hooks/useModal";
import Typography from "@material-ui/core/Typography";

const DeleteModal = ({ deleteFunc }: { deleteFunc: (a: any) => any }) => {
  const [deleteModal, setDeleteShow] = useModal("deleteData");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onClickDeleteButton = async () => {
    setLoading(true);
    await dispatch(deleteFunc(deleteModal.meta?.item));
    setLoading(false);
    setDeleteShow(false);
  };
  return (
    <Modal show={deleteModal.show} setShow={setDeleteShow} title="削除">
      <Container>
        <Typography>本当に削除しますか？</Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          onClick={onClickDeleteButton}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "決定"}
        </Button>
      </Container>
    </Modal>
  );
};

export default DeleteModal;
