import React, { useCallback, useContext, useMemo, useState } from "react";
import { INode, INodeInnerDefaultProps } from "@bastinjafari/react-flow-chart";
import styled from "styled-components";
import { MenuItem, TextField, Box } from "@material-ui/core";
import { ChartContext } from "./index";
import { BorderColor } from "types/enum/BorderColor";
import { TextColor } from "types/enum/TextColor";
import { DateTime } from "luxon";
import { MoreVert } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const Container = styled.div`
  padding: 16px;
  max-width: 1200px;
  width: 600px;
  position: relative;
  > * {
    margin-bottom: 16px;
  }
`;

const Title = styled.div`
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  font-size: 16px;
  padding: 0 0 16px 0;
  align-items: center;
  display: flex;

  > * {
    margin-right: 8px;
  }
`;

const ListContainer = styled.ul`
  list-style: inside;
`;

const QuestionNode = ({ node }: { node: INode }) => {
  const [popup, setPopup] = useState(false);
  const { setChart } = useContext(ChartContext);
  const onTextBlur = useCallback(
    (e: any, name: string) => {
      const id = node.id;
      e.stopPropagation();
      setChart((prev) => {
        const currentNodes = prev.nodes;
        const currentNode = prev.nodes[id];
        const currentProperties = prev.nodes[id].properties;

        return {
          ...prev,
          nodes: {
            ...currentNodes,
            [id]: {
              ...currentNode,
              properties: { ...currentProperties, [name]: e.target.value },
            },
          },
        };
      });
    },
    [setChart]
  );
  return (
    <Container>
      <Title>
        <NodeTextfield
          label="タイトル"
          value={node.properties.title}
          name="title"
          nodeId={node.id}
          onBlur={onTextBlur}
        />
      </Title>
      <ListContainer>
        <NodeImageField nodeId={node.id} value={node.properties.imageUrl} />
      </ListContainer>
      <ListContainer>
        <NodeTextfield
          label="説明文"
          value={node.properties.description}
          name="description"
          nodeId={node.id}
          rows={3}
          onBlur={onTextBlur}
        />
      </ListContainer>
      <Box display="flex" justifyContent="flex-end">
        <MoreVert
          style={{ cursor: "pointer" }}
          onClick={() => setPopup(true)}
        />
      </Box>
      {popup && (
        <Box
          display="flex"
          flexDirection="column"
          style={{
            position: "absolute",
            backgroundColor: "white",
            padding: "16px",
            top: 0,
            right: 0,
          }}
        >
          <HighlightOffIcon
            onClick={() => setPopup(false)}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
          <NodeSelectField
            label="タイトルの文字色"
            value={node.properties.titleColor}
            name="titleColor"
            nodeId={node.id}
            object={TextColor}
          />
          <NodeSelectField
            label="説明文の文字色"
            value={node.properties.descriptionColor}
            name="descriptionColor"
            nodeId={node.id}
            object={TextColor}
          />
          <NodeSelectField
            label="枠線の色"
            value={node.properties.borderColor}
            name="borderColor"
            nodeId={node.id}
            object={BorderColor}
          />
          <NodeTimeFiled
            nodeId={node.id}
            startTime={node.properties.startTime}
            endTime={node.properties.endTime}
          />
        </Box>
      )}
    </Container>
  );
};

const NodeTextfield = React.memo(
  ({
    label,
    name,
    value,
    rows = 1,
    onBlur,
  }: {
    nodeId: string;
    name: string;
    label: string;
    value: string;
    rows?: number;
    onBlur: any;
  }) => {
    const [text, setText] = useState(value);
    return (
      <TextField
        required
        multiline
        label={label}
        variant="outlined"
        fullWidth
        rows={rows}
        rowsMax={1000}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onBlur={(e) => onBlur(e, name)}
        onClick={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      />
    );
  }
);

const NodeSelectField = ({
  nodeId,
  name,
  label,
  value,
  object,
}: {
  nodeId: string;
  name: string;
  label: string;
  value: string;
  object: { [key: string]: string };
}) => {
  const { setChart } = useContext(ChartContext);
  const onChange = (e: any) => {
    const id = nodeId;
    e.stopPropagation();
    setChart((prev) => {
      const currentNodes = prev.nodes;
      const currentNode = prev.nodes[id];
      const currentProperties = prev.nodes[id].properties;

      return {
        ...prev,
        nodes: {
          ...currentNodes,
          [id]: {
            ...currentNode,
            properties: { ...currentProperties, [name]: e.target.value },
          },
        },
      };
    });
  };

  return (
    <TextField
      select
      label={label}
      variant="outlined"
      fullWidth
      value={value || ""}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <option value="" />
      {Object.entries(object).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      ))}
    </TextField>
  );
};

const NodeImageField = ({
  nodeId,
  value,
}: {
  nodeId: string;
  value: string;
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const { setChart } = useContext(ChartContext);
  const imageUpload = (id: string) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    if (!event.target.files) return;
    setImageUrl(URL.createObjectURL(event.target.files![0]));
    const dataUri = await getBase64(event.target.files![0]);
    setChart((prev) => {
      const currentNodes = prev.nodes;
      const currentNode = prev.nodes[id];
      const currentProperties = prev.nodes[id].properties;
      return {
        ...prev,
        nodes: {
          ...currentNodes,
          [id]: {
            ...currentNode,
            properties: {
              ...currentProperties,
              imageUrl: dataUri,
            },
          },
        },
      };
    });
  };
  const getBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = "";
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const removeImage = (id: string) => {
    setChart((prev) => {
      const currentNodes = prev.nodes;
      const currentNode = prev.nodes[id];
      const currentProperties = prev.nodes[id].properties;
      return {
        ...prev,
        nodes: {
          ...currentNodes,
          [id]: {
            ...currentNode,
            properties: {
              ...currentProperties,
              imageUrl: "",
            },
          },
        },
      };
    });
    setImageUrl("");
  };
  return (
    <>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif"
        onChange={imageUpload(nodeId)}
      />
      {(value || imageUrl) && (
        <Box
          display="flex"
          justifyContent="center"
          style={{ position: "relative" }}
        >
          <HighlightOffIcon
            style={{
              position: "absolute",
              cursor: "pointer",
              top: 0,
              right: 0,
            }}
            onClick={() => removeImage(nodeId)}
          />
          <img
            src={
              imageUrl ||
              `https://tsplayer-image.s3-ap-northeast-1.amazonaws.com/${value}`
            }
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

const NodeTimeFiled = ({
  nodeId,
  startTime,
  endTime,
}: {
  nodeId: string;
  startTime: Date;
  endTime: Date;
}) => {
  const { setChart } = useContext(ChartContext);
  const timeChange = (id: string, type: string) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    if (!event.target.value) return;
    setChart((prev) => {
      const currentNodes = prev.nodes;
      const currentNode = prev.nodes[id];
      const currentProperties = prev.nodes[id].properties;
      return {
        ...prev,
        nodes: {
          ...currentNodes,
          [id]: {
            ...currentNode,
            properties: {
              ...currentProperties,
              [type]: new Date(event.target.value),
            },
          },
        },
      };
    });
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <TextField
        label="掲載開始日"
        type="datetime-local"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={timeChange(nodeId, "startTime")}
        value={
          startTime
            ? DateTime.fromJSDate(startTime).toFormat("yyyy-MM-dd'T'hh:mm")
            : undefined
        }
      />
      ~
      <TextField
        label="掲載終了日"
        type="datetime-local"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={timeChange(nodeId, "endTime")}
        value={
          endTime
            ? DateTime.fromJSDate(endTime).toFormat("yyyy-MM-dd'T'hh:mm")
            : undefined
        }
      />
    </Box>
  );
};

const EditableNodeInner = ({ node }: INodeInnerDefaultProps) => {
  return <QuestionNode node={node} />;
};

export default EditableNodeInner;
