import { createSlice } from "@reduxjs/toolkit";
import SectionRes from "../../types/res/SectionRes";
import {
  createSection,
  deleteSection,
  fetchSection,
  updateSection,
} from "../actions/section";

export type SectionState = { [id: string]: SectionRes };

const initialState = {} as SectionState;

const configureSection = (raw: SectionRes[]) => {
  const result = {} as SectionState;
  if (!raw) return result;
  raw.forEach((section) => {
    result[section.sectionId] = section;
  });
  return result;
};
const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSection.fulfilled, (_, { payload }) =>
      configureSection(payload)
    );
    builder.addCase(createSection.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.sectionId]: payload.payload,
    }));
    builder.addCase(updateSection.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.sectionId]: payload.payload,
    }));

    builder.addCase(deleteSection.fulfilled, (state, payload) => {
      delete state[payload.payload.sectionId];
      return state;
    });
  },
});

export default sectionSlice.reducer;
