import { createSlice } from "@reduxjs/toolkit";
import FaqRes from "../../types/res/FaqRes";
import { createFaq, deleteFaq, fetchFaq, updateFaq } from "../actions/faq";

export type FaqState = { [id: string]: FaqRes };

const initialState = {} as FaqState;

const configureFaq = (raw: FaqRes[]) => {
  const result = {} as FaqState;
  if (!raw) return result;
  raw.forEach((faq) => {
    result[faq.faqId] = faq;
  });
  return result;
};
const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFaq.fulfilled, (_, { payload }) =>
      configureFaq(payload)
    );
    builder.addCase(createFaq.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.faqId]: payload.payload,
    }));
    builder.addCase(updateFaq.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.faqId]: payload.payload,
    }));
    builder.addCase(deleteFaq.fulfilled, (state, payload) => {
      delete state[payload.payload.faqId];
      return state;
    });
  },
});

export default faqSlice.reducer;
