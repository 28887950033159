import UserRes from "../../types/res/UserRes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  createUser,
  deleteUser,
  fetchUser,
  updateUser,
} from "../../redux/actions/user";
import { FormType } from "../../components/FormField";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import CreateUserReq from "../../types/req/user/CreateUserReq";
import UpdateUserReq from "../../types/req/user/UpdateUserReq";
import { fetchSection } from "../../redux/actions/section";
import { fetchCompany } from "../../redux/actions/company";
import { UserRole } from "../../types/enum/UserRole";
import EnumUtils from "../../utils/EnumUtils";

const headers: { key: keyof UserRes; label: string }[] = [
  { key: "companyId", label: "会社名" },
  { key: "sectionId", label: "拠点名" },
  { key: "name", label: "名前" },
  { key: "email", label: "メールアドレス" },
  { key: "role", label: "権限" },
];

const User = () => {
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.section);
  const users = useSelector((state) => state.user);
  const companies = useSelector((state) => state.company);
  const loginUserRole = useSelector((state) => state.account.user.role);
  const loginUserCompany = useSelector((state) => state.account.user.company);
  useEffect(() => {
    if (EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master) {
      dispatch(fetchCompany());
    }
    dispatch(fetchSection());
    dispatch(fetchUser());
  }, [loginUserRole]);
  const forms = [
    {
      label: "会社",
      key: "companyId",
      type: FormType.Select,
      options:
        EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master
          ? Object.values(companies).map((company) => ({
              id: company.companyId,
              name: company.name,
            }))
          : [
              {
                id: loginUserCompany?.companyId || "",
                name: loginUserCompany?.name || "未設定",
              },
            ],
    },
    {
      label: "拠点",
      key: "sectionId",
      type: FormType.Select,
      options: Object.values(sections)
        .filter((section) => {
          if (
            EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.manager
          ) {
            return section.companyId === loginUserCompany?.companyId || "";
          } else {
            return true;
          }
        })
        .map((section) => ({
          id: section.sectionId,
          name: section.name,
        })),
    },
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "権限",
      key: "role",
      type: FormType.Select,
      options:
        EnumUtils.mapToEnum(UserRole, loginUserRole) === UserRole.master
          ? Object.entries(UserRole).map(([key, value]) => ({
              id: key,
              name: value,
            }))
          : [{ id: UserRole.viewer.toString(), name: UserRole.viewer }],
    },
    {
      label: "パスワード",
      key: "password",
      type: FormType.Text,
    },
  ];
  return (
    <UserTable
      title={"ユーザー"}
      rows={headers}
      formId={"userForm"}
      forms={forms}
      data={Object.values(users)}
      values={[
        (u) => u.company?.name || "未設定",
        (u) => u.section?.name || "未設定",
        (u) => u.name,
        (u) => u.email,
        (u) => EnumUtils.mapToEnum(UserRole, u.role) || "未設定",
      ]}
      addFunc={(req) => createUser({ req })}
      updateFunc={(req) => updateUser({ req })}
      deleteFunc={(req) => deleteUser({ req: { userId: req?.userId } })}
      addType={CreateUserReq}
      updateType={UpdateUserReq}
      addRole={[UserRole.master, UserRole.manager]}
      editRole={[UserRole.master, UserRole.manager]}
      deleteRole={[UserRole.master, UserRole.manager]}
    />
  );
};

const UserTable = styled<
  React.FC<CommonTableProps<UserRes, CreateUserReq, UpdateUserReq>>
>(CommonTable)`
  margin-top: 24px;
`;
export default User;
