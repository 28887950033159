import RequestUtils from "../utils/RequestUtils";
import CompanyRes from "../types/res/CompanyRes";
import CreateCompanyReq from "../types/req/company/CreateCompanyReq";
import UpdateCompanyReq from "../types/req/company/UpdateCompanyReq";
import DeleteCompanyReq from "../types/req/company/DeleteCompanyReq";

export default class CompanyApi {
  static async findAll() {
    return await RequestUtils.getArray(CompanyRes, `/company`);
  }

  static async create(req: CreateCompanyReq) {
    return await RequestUtils.post(CompanyRes, `/company`, req);
  }

  static async update(req: UpdateCompanyReq) {
    return await RequestUtils.put(CompanyRes, `/company`, req);
  }

  static async delete(req: DeleteCompanyReq) {
    return await RequestUtils.delete(CompanyRes, `/company`, req);
  }
}
