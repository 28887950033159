import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyApi from "../../api/CompanyApi";
import CreateCompanyReq from "../../types/req/company/CreateCompanyReq";
import UpdateCompanyReq from "../../types/req/company/UpdateCompanyReq";
import DeleteCompanyReq from "../../types/req/company/DeleteCompanyReq";

export const fetchCompany = createAsyncThunk(
  "company/fetch",
  async () => await CompanyApi.findAll()
);

export const createCompany = createAsyncThunk(
  "company/create",
  async ({ req }: { req: CreateCompanyReq }) => await CompanyApi.create(req)
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async ({ req }: { req: UpdateCompanyReq }) => await CompanyApi.update(req)
);

export const deleteCompany = createAsyncThunk(
  "company/delete",
  async ({ req }: { req: DeleteCompanyReq }) => await CompanyApi.delete(req)
);
