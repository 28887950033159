import { createAsyncThunk } from "@reduxjs/toolkit";
import UserApi from "../../api/UserApi";
import CreateUserReq from "../../types/req/user/CreateUserReq";
import UpdateUserReq from "../../types/req/user/UpdateUserReq";
import DeleteUserReq from "../../types/req/user/DeleteUserReq";

export const fetchUser = createAsyncThunk(
  "user/fetch",
  async () => await UserApi.findAll()
);

export const createUser = createAsyncThunk(
  "user/create",
  async ({ req }: { req: CreateUserReq }, { rejectWithValue }) => {
    try {
      return await UserApi.create(req);
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async ({ req }: { req: UpdateUserReq }) => await UserApi.update(req)
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ req }: { req: DeleteUserReq }) => await UserApi.delete(req)
);
