import RequestUtils from "../utils/RequestUtils";
import SectionRes from "../types/res/SectionRes";
import CreateSectionReq from "../types/req/section/CreateSectionReq";
import UpdateSectionReq from "../types/req/section/UpdateSectionReq";
import DeleteSectionReq from "../types/req/section/DeleteSectionReq";

export default class SectionApi {
  static async findAll() {
    return await RequestUtils.getArray(SectionRes, `/section`);
  }

  static async create(req: CreateSectionReq) {
    return await RequestUtils.post(SectionRes, `/section`, req);
  }

  static async update(req: UpdateSectionReq) {
    return await RequestUtils.put(SectionRes, `/section`, req);
  }

  static async delete(req: DeleteSectionReq) {
    return await RequestUtils.delete(SectionRes, `/section`, req);
  }
}
