import { reducerWithInitialState } from "typescript-fsa-reducers";
import modalActions from "redux/actions/modals";

export type ModalState = {
  [key: string]: { show: boolean; meta?: any };
};

const initialState: ModalState = {
  signin: { show: false },
  addData: { show: false, meta: {} },
  editData: { show: false, meta: {} },
  deleteData: { show: false, meta: {} },
  companyForm: { show: false, meta: {} },
};

const modalsReducer = reducerWithInitialState(initialState).case(
  modalActions.setModalShow,
  (state, { id, show, meta }) => {
    return { ...state, [id]: { show, meta } };
  }
);

export default modalsReducer;
