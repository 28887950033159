import { createAsyncThunk } from "@reduxjs/toolkit";
import FaqApi from "../../api/FaqApi";
import CreateFaqReq from "../../types/req/faq/CreateFaqReq";
import UpdateFaqReq from "../../types/req/faq/UpdateFaqReq";
import DeleteFaqReq from "../../types/req/faq/DeleteFaqReq";

export const fetchFaq = createAsyncThunk(
  "faq/fetch",
  async () => await FaqApi.findAll()
);

export const createFaq = createAsyncThunk(
  "faq/create",
  async ({ req }: { req: CreateFaqReq }) => await FaqApi.create(req)
);

export const updateFaq = createAsyncThunk(
  "faq/update",
  async ({ req }: { req: UpdateFaqReq }) => await FaqApi.update(req)
);

export const deleteFaq = createAsyncThunk(
  "faq/delete",
  async ({ req }: { req: DeleteFaqReq }) => await FaqApi.delete(req)
);
