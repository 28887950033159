import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  loading: boolean;
};

const Loader: React.FC<Props> = ({ loading = false, children }) => {
  if (loading)
    return (
      <Container>
        <CircularProgress size={100} color="primary" />
      </Container>
    );
  return <>{children}</>;
};

export default Loader;
