import React from "react";
import styled from "styled-components";
import {
  AppBar as MuiAppBar,
  Button,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { blue, grey } from "@material-ui/core/colors";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UserRoleLayer from "components/UserRoleLayer";
import { logout } from "../redux/actions/account";
import { UserRole } from "types/enum/UserRole";

const theme = {
  color: "#333333",
  background: "#FFFFFF",
  search: {
    color: grey[800],
  },
  indicator: {
    background: blue[600],
  },
};

const AppBar = styled(MuiAppBar)`
  background: ${theme.background};
  color: ${theme.color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
`;

const Logo = styled(Link)`
  display: flex;
  justify-self: center;
  margin-right: 24px;
  img {
    height: 28px;
  }
`;

const IconButton: any = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type Props = {
  onDrawerToggle?: () => void;
};

const NavsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    margin-right: 16px;
  }
`;

const Navs = () => {
  return (
    <NavsContainer>
      <UserRoleLayer types={[UserRole.master]}>
        <NavLink to="/company">会社</NavLink>
        <NavLink to="/section">拠点</NavLink>
      </UserRoleLayer>
      <UserRoleLayer types={[UserRole.master, UserRole.manager]}>
        <NavLink to="/user">ユーザー</NavLink>
        <NavLink to="/store">導入店舗</NavLink>
        <NavLink to="/faq">FAQ</NavLink>
        <NavLink to="/workflows">ワークフロー</NavLink>
      </UserRoleLayer>
    </NavsContainer>
  );
};

const Header: React.FC<Props> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => Boolean(state.account.accessToken));
  const onClickLogout = () => {
    dispatch(logout());
  };
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justify="center">
            <Grid item style={{ marginRight: "auto", display: "flex" }}>
              <Logo to="/">Logo</Logo>
              <Navs />
            </Grid>
            <Hidden smDown>
              {isLoggedIn && (
                <Button onClick={onClickLogout}>ログアウト</Button>
              )}
            </Hidden>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
