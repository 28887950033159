import { Expose, Type } from "class-transformer";
import SectionRes from "./SectionRes";

export default class StoreRes {
  @Expose() readonly storeId!: number;
  @Expose() readonly sectionId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly address!: string;
  @Type(() => SectionRes)
  @Expose()
  readonly section!: SectionRes;
}
