import { createSlice } from "@reduxjs/toolkit";
import StoreRes from "../../types/res/StoreRes";
import {
  createStore,
  deleteStore,
  fetchStore,
  updateStore,
} from "../actions/store";

export type StoreState = { [id: string]: StoreRes };

const initialState = {} as StoreState;

const configureStore = (raw: StoreRes[]) => {
  const result = {} as StoreState;
  if (!raw) return result;
  raw.forEach((store) => {
    result[store.storeId] = store;
  });
  return result;
};
const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStore.fulfilled, (_, { payload }) =>
      configureStore(payload)
    );
    builder.addCase(createStore.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.storeId]: payload.payload,
    }));
    builder.addCase(updateStore.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.storeId]: payload.payload,
    }));

    builder.addCase(deleteStore.fulfilled, (state, payload) => {
      delete state[payload.payload.storeId];
      return state;
    });
  },
});

export default storeSlice.reducer;
