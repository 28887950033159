import React from "react";
import styled from "styled-components";

import LoginCard from "components/LoginCard";

const Container = styled.div`
  width: 40%;
  margin: 50px auto;
`;

const Signin = () => {
  return (
    <Container>
      <LoginCard />
    </Container>
  );
};
export default Signin;
