import { IsNotEmpty, MaxLength } from "class-validator";
import { DataValidationError } from "constants/DataValidationError";
import Spec from "constants/Spec";

export default class UpdateCompanyReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  companyId!: number;
  @MaxLength(Spec.maxLength.company.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.company.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @MaxLength(Spec.maxLength.company.tel, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.company.tel,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  tel!: string;

  constructor(arg: { companyId: number; name: string; tel: string }) {
    arg = arg || {};
    this.companyId = arg.companyId;
    this.name = arg.name;
    this.tel = arg.tel;
  }
}
