import SectionRes from "../../types/res/SectionRes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  createSection,
  deleteSection,
  fetchSection,
  updateSection,
} from "../../redux/actions/section";
import { FormType } from "../../components/FormField";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import CreateSectionReq from "../../types/req/section/CreateSectionReq";
import UpdateSectionReq from "../../types/req/section/UpdateSectionReq";
import { fetchCompany } from "../../redux/actions/company";
import { UserRole } from "../../types/enum/UserRole";

const headers: { key: keyof SectionRes; label: string }[] = [
  { key: "company", label: "会社名" },
  { key: "name", label: "名前" },
  { key: "tel", label: "電話番号" },
];

const Section = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company);
  const sections = useSelector((state) => state.section);
  useEffect(() => {
    dispatch(fetchSection());
    dispatch(fetchCompany());
  }, []);
  const forms = [
    {
      label: "会社",
      key: "companyId",
      type: FormType.Select,
      options: Object.values(companies).map((company) => ({
        id: company.companyId,
        name: company.name,
      })),
    },
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
  ];
  return (
    <SectionTable
      title={"拠点"}
      rows={headers}
      formId={"sectionForm"}
      forms={forms}
      data={Object.values(sections)}
      values={[(c) => c.company.name, (c) => c.name, (c) => c.tel]}
      addFunc={(req) => createSection({ req })}
      updateFunc={(req) => updateSection({ req })}
      deleteFunc={(req) =>
        deleteSection({ req: { sectionId: req?.sectionId } })
      }
      addType={CreateSectionReq}
      updateType={UpdateSectionReq}
      addRole={[UserRole.master]}
      editRole={[UserRole.master]}
      deleteRole={[UserRole.master]}
    />
  );
};

const SectionTable = styled<
  React.FC<CommonTableProps<SectionRes, CreateSectionReq, UpdateSectionReq>>
>(CommonTable)`
  margin-top: 24px;
`;
export default Section;
