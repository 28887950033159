import { DataValidationError } from "constants/DataValidationError";
import {
  IsAscii,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import { UserRole } from "types/enum/UserRole";

export default class CreateUserReq {
  @IsOptional()
  companyId?: number;

  @IsOptional()
  sectionId?: number;

  @MaxLength(Spec.maxLength.user.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.user.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @MaxLength(Spec.maxLength.user.name, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.user.name,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  name!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.user.password, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.user.password,
    },
  })
  @IsAscii({
    message: DataValidationError.DATA_IS_INVALID,
  })
  password!: string;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  role!: UserRole;

  constructor(arg: {
    companyId?: number;
    sectionId?: number;
    name: string;
    email: string;
    password: string;
    role: UserRole;
  }) {
    arg = arg || {};
    this.companyId = arg.companyId;
    this.sectionId = arg.sectionId;
    this.name = arg.name;
    this.email = arg.email;
    this.password = arg.password;
    this.role = arg.role;
  }
}
