import { createSlice } from "@reduxjs/toolkit";
import UserRes from "../../types/res/UserRes";
import { createUser, deleteUser, fetchUser, updateUser } from "../actions/user";

export type UserState = { [id: string]: UserRes };

const initialState = {} as UserState;

const configureUser = (raw: UserRes[]) => {
  const result = {} as UserState;
  if (!raw) return result;
  raw.forEach((user) => {
    result[user.userId] = user;
  });
  return result;
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (_, { payload }) =>
      configureUser(payload)
    );
    builder.addCase(createUser.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.userId]: payload.payload,
    }));
    builder.addCase(updateUser.fulfilled, (state, payload) => ({
      ...state,
      [payload.payload.userId]: payload.payload,
    }));
    builder.addCase(deleteUser.fulfilled, (state, payload) => {
      delete state[payload.payload.userId];
      return state;
    });
  },
});

export default userSlice.reducer;
