import FaqRes from "../../types/res/FaqRes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  createFaq,
  deleteFaq,
  fetchFaq,
  updateFaq,
} from "../../redux/actions/faq";
import { FormType } from "../../components/FormField";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "../../components/CommonTable";
import CreateFaqReq from "../../types/req/faq/CreateFaqReq";
import UpdateFaqReq from "../../types/req/faq/UpdateFaqReq";
import { fetchStore } from "../../redux/actions/store";
import { UserRole } from "../../types/enum/UserRole";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { fetchCompany } from "../../redux/actions/company";
import { fetchSection } from "../../redux/actions/section";

const headers: { key: keyof FaqRes; label: string }[] = [
  { key: "store", label: "会社名" },
  { key: "store", label: "拠点名" },
  { key: "store", label: "導入店舗" },
  { key: "question", label: "質問" },
  { key: "answer", label: "回答" },
];

const Faq = () => {
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.store);
  const faqs = useSelector((state) => state.faq);
  const companies = useSelector((state) => state.company);
  const sections = useSelector((state) => state.section);
  const [filterFaqs, setFilterFaqs] = useState<any[]>([]);
  const [selectCompany, setSelectCompany] = useState<string[]>([]);
  const [selectSection, setSelectSection] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (!searchText) return setFilterFaqs(Object.values(faqs));
    setFilterFaqs(
      Object.values(faqs).filter(
        (faq: any) =>
          ~faq.question.indexOf(searchText) || ~faq.answer.indexOf(searchText)
      )
    );
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchFaq());
    dispatch(fetchStore());
    dispatch(fetchCompany());
    dispatch(fetchSection());
  }, []);

  useEffect(() => {
    setFilterFaqs(Object.values(faqs));
  }, [faqs]);

  useEffect(() => {
    setSelectCompany([
      ...Object.values(companies).map((company) => String(company.companyId)),
      "すべて",
    ]);
  }, [companies]);

  useEffect(() => {
    setSelectSection([
      ...Object.values(sections).map((section) => String(section.sectionId)),
      "すべて",
    ]);
  }, [sections]);

  useEffect(() => {
    setFilterFaqs(
      Object.values(faqs).filter((faq) =>
        selectCompany.includes(String(faq.store.section.companyId))
      )
    );
  }, [selectCompany]);

  useEffect(() => {
    setFilterFaqs(
      Object.values(faqs).filter((faq) =>
        selectSection.includes(String(faq.store.section.sectionId))
      )
    );
  }, [selectSection]);

  const forms = [
    {
      label: "導入店舗",
      key: "storeId",
      type: FormType.Select,
      options: Object.values(stores).map((store) => ({
        id: store.storeId,
        name: store.name,
      })),
    },
    {
      label: "質問",
      key: "question",
      type: FormType.Text,
    },
    {
      label: "回答",
      key: "answer",
      type: FormType.Text,
    },
  ];
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="flex-end">
          <Box>
            <InputLabel id="select-multiple-company">会社</InputLabel>
            <Select
              multiple
              value={selectCompany}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectCompany((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...Object.values(companies).map((company) =>
                        String(company.companyId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      Object.keys(companies).length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...Object.values(companies).map((company) =>
                        String(company.companyId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    Object.keys(companies).length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-company" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.values(companies)
                    .filter((company) =>
                      (selected as string[]).includes(String(company.companyId))
                    )
                    .map((company) => company.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectCompany.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.values(companies).map((company) => (
                <MenuItem
                  key={company.companyId}
                  value={String(company.companyId)}
                >
                  <Checkbox
                    checked={
                      selectCompany.indexOf(String(company.companyId)) !== -1
                    }
                  />
                  <ListItemText primary={company.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-section">拠点</InputLabel>
            <Select
              multiple
              value={selectSection}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectSection((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...Object.values(sections).map((section) =>
                        String(section.sectionId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      Object.keys(sections).length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...Object.values(sections).map((section) =>
                        String(section.sectionId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    Object.keys(sections).length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-section" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return Object.values(sections)
                    .filter((section) =>
                      (selected as string[]).includes(String(section.sectionId))
                    )
                    .map((section) => section.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectCompany.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {Object.values(sections).map((section) => (
                <MenuItem
                  key={section.sectionId}
                  value={String(section.sectionId)}
                >
                  <Checkbox
                    checked={
                      selectSection.indexOf(String(section.sectionId)) !== -1
                    }
                  />
                  <ListItemText primary={section.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <TextField
              placeholder="検索文字を入力..."
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <FaqTable
        title={"FAQ"}
        rows={headers}
        formId={"faqForm"}
        forms={forms}
        data={filterFaqs}
        values={[
          (f) => f.store.section.company.name,
          (f) => f.store.section.name,
          (f) => f.store.name,
          (f) => f.question,
          (f) => f.answer,
        ]}
        addFunc={(req) => createFaq({ req })}
        updateFunc={(req) => updateFaq({ req })}
        deleteFunc={(req) => deleteFaq({ req: { faqId: req?.faqId } })}
        addType={CreateFaqReq}
        updateType={UpdateFaqReq}
        addRole={[UserRole.master, UserRole.manager]}
        editRole={[UserRole.master, UserRole.manager]}
        deleteRole={[UserRole.master, UserRole.manager]}
      />
    </>
  );
};

const FaqTable = styled<
  React.FC<CommonTableProps<FaqRes, CreateFaqReq, UpdateFaqReq>>
>(CommonTable)`
  margin-top: 24px;
`;
export default Faq;
