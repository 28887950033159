import { Expose, Type } from "class-transformer";
import { UserRole } from "types/enum/UserRole";
import SectionRes from "./SectionRes";
import CompanyRes from "./CompanyRes";

export default class UserRes {
  @Expose() readonly companyId?: number;
  @Expose() readonly sectionId?: number;
  @Expose() readonly userId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly email!: string;
  @Expose() readonly role!: UserRole;
  @Type(() => CompanyRes)
  @Expose()
  readonly company?: CompanyRes;
  @Type(() => SectionRes)
  @Expose()
  readonly section?: SectionRes;
}
